import React, { useEffect, useRef, useState } from "react";
import { User } from "firebase/auth";
import {
  collection,
  query,
  where,
  doc,
  setDoc,
  serverTimestamp,
  updateDoc,
  QuerySnapshot,
  DocumentData,
  getDocs,
  getDoc,
  DocumentSnapshot,
} from "firebase/firestore";
import { db, analytics } from "../../../firebase/firebase";
import { SubmitHandler, useForm } from "react-hook-form";

import {
  IonButton,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonRadio,
  IonRadioGroup,
  IonThumbnail,
  IonToast,
} from "@ionic/react";

import "./QuestionModal.css";
import { closeOutline } from "ionicons/icons";
import { state } from "../../../store";
import { AnswerOption, Question } from "../../../models/question";
import { Answer } from "../../../models/answer";
import { useAuthUserContext } from "../../../context/AuthUserContext";
import { useExperienceContext } from "../../../context/ExperienceContext";

interface ContainerProps {
  question: Question;
  questionModalIsOpen: boolean;
  setQuestionModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

type FormValues = {
  selectedAnswerOption: AnswerOption;
};

const QuestionModal: React.FC<ContainerProps> = ({
  question,
  questionModalIsOpen,
  setQuestionModalIsOpen,
}) => {
  // Context API
  const { authUser, authUserIsLoading } = useAuthUserContext();
  const {
    tenant,
    brand,
    event,
    experience,
    placementId,
    set,
    experienceProducts,
    experienceQuestions,
    experienceVideos,
    cartProductVariants,
    listProductVariants,
    stores,
    userAccount,
    experienceIsLoading,
  } = useExperienceContext();

  const [loading, setLoading] = useState<boolean>(false);
  const [showConfirmationToast, setShowConfirmationToast] = useState(false);

  const modal = useRef<HTMLIonModalElement>(null);

  const onDismissModal = () => {
    setQuestionModalIsOpen(false);
    state.isModalOpen = false;
  };

  const { register, handleSubmit } = useForm<FormValues>();
  const onSubmit: SubmitHandler<FormValues> = (data) => console.log(data);

  /**
   * Submit answer to question
   * @param answerOption
   */
  const submitAnswer = async (formValues: FormValues) => {
    try {
      setLoading(true);
      if (!authUser?.uid)
        throw new Error("Invalid user account. Please contact support.");
      if (!formValues.selectedAnswerOption.id)
        throw new Error("Please select an answer to submit.");

      // Use an answer ID that is the questionId + userAccountId
      const answerId: string = question.id + authUser?.uid;

      // check if answer was already submitted
      let existingAnswer: boolean = false;
      try {
        const existDoc: any = await getDoc(doc(db, `answers/${answerId}`));
        if (existDoc) existingAnswer = true;
      } catch (err) {
        //ignorefirestore permissions error that could occur if no document exists
      }

      const answer: Partial<Answer> = {
        id: answerId,
        tenantId: tenant?.id,
        brandId: brand?.id,
        eventId: experience?.eventId,
        experienceId: experience?.id,
        placementId: placementId,
        questionId: question.id,
        answerOptionId: formValues.selectedAnswerOption.id,
        userAccountId: authUser?.uid,
        deleted: false,
      };

      if (existingAnswer) {
        answer.updatedDate = serverTimestamp();
        answer.updatedByUserAccountId = authUser?.uid;
      } else {
        answer.createdDate = serverTimestamp();
        answer.createdByUserAccountId = authUser?.uid;
      }

      await setDoc(doc(db, `answers/${answerId}`), answer, { merge: true });

      setShowConfirmationToast(true);
      setQuestionModalIsOpen(false);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      alert(err);
    }
  };

  return (
    <React.Fragment>
      <IonModal
        className="fullwidth"
        ref={modal}
        isOpen={questionModalIsOpen}
        onDidDismiss={onDismissModal}
        initialBreakpoint={1}
        breakpoints={[0, 1]}
        showBackdrop={false}
        backdropDismiss={false}
        backdropBreakpoint={0.5} // disable backdrop and allow for interaction with the 3D environment while modal is displayed
        animated={false}
      >
        <IonContent color="light">
          <IonFab slot="fixed" horizontal="end" vertical="top">
            <IonFabButton
              color={"primary"}
              id="open-modal"
              size="small"
              onClick={onDismissModal}
            >
              <IonIcon icon={closeOutline}></IonIcon>
            </IonFabButton>
          </IonFab>

          <div className="ion-margin ion-text-wrap">
            <h2>{question?.questionText}</h2>
          </div>
          <form
            onSubmit={handleSubmit(async (data) => await submitAnswer(data))}
          >
            <IonList>
              <IonRadioGroup>
                {question &&
                  question.answerOptions &&
                  question.answerOptions.map(function (answerOption, index) {
                    return (
                      <IonItem key={index} id={"answer" + String(index)}>
                        <IonLabel className="ion-text-wrap">
                          {answerOption.answerOptionText}
                        </IonLabel>
                        <IonRadio
                          {...register("selectedAnswerOption")}
                          value={answerOption}
                          slot="start"
                        ></IonRadio>
                      </IonItem>
                    );
                  })}
              </IonRadioGroup>
            </IonList>
            <IonButton
              color={"success"}
              fill={"solid"}
              expand={"block"}
              className="ion-margin"
              type="submit"
              id="submit-answer"
            >
              Submit
            </IonButton>
          </form>
        </IonContent>
        {/*/!* Loading spinner until event data is provided *!/*/}
        <IonLoading isOpen={loading}></IonLoading>
      </IonModal>

      <IonToast
        position="top"
        isOpen={showConfirmationToast}
        onDidDismiss={() => setShowConfirmationToast(false)}
        message="Your response has been saved!"
        duration={3000}
      ></IonToast>
    </React.Fragment>
  );
};

export default QuestionModal;
