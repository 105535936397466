import { useEffect, useState } from "react";
import { Experience } from "../../../models/experience";
import "./ExperienceNotLive.css";
import DataTransformationService from "../../../services/data-transformation.service";
import { Timestamp } from "firebase/firestore";
import { IonCard, IonCardContent, IonCol, IonGrid, IonRow } from "@ionic/react";
import { useAuthUserContext } from "../../../context/AuthUserContext";
import { useExperienceContext } from "../../../context/ExperienceContext";

const ExperienceNotStarted: React.FC = () => {
  // Context API
  const { authUser, authUserIsLoading } = useAuthUserContext();
  const {
    tenant,
    brand,
    event,
    experience,
    placementId,
    set,
    experienceProducts,
    experienceQuestions,
    experienceVideos,
    cartProductVariants,
    listProductVariants,
    stores,
    userAccount,
    experienceIsLoading,
  } = useExperienceContext();

  return (
    <>
      {experience && (
        <div
          className="not-live-page-background"
          style={{
            backgroundImage:
              'url("/assets/textures/one-bedroom-house/bgblurreddesktop.webp")',
          }}
        >
          <div className="not-live-page-container">
            <IonCard className="not-live-page-card">
              <IonCardContent>
                <IonGrid>
                  <IonRow>
                    <IonCol size="12" className="not-live-page-col">
                      <img
                        src="/assets/images/space-invaders-hero.jpg"
                        className="not-live-page-hero-image"
                        alt="Space Invaders"
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="12" className="not-live-page-col">
                      {/* Before start date */}
                      {experience &&
                        (
                          experience?.experienceStartDate as Timestamp
                        ).toMillis() > Date.now() && (
                          <>
                            <p style={{ fontSize: "1.2em" }}>Welcome!</p>
                            <p style={{ fontSize: "1.2em" }}>
                              The Virtual Store opens this Saturday at 7.30pm
                              AEST, during the first broadcast episode of Space
                              Invaders on Nine.
                            </p>
                          </>
                        )}

                      {/* Past end date */}
                      {experience &&
                        (
                          experience?.experienceEndDate as Timestamp
                        ).toMillis() < Date.now() && (
                          <>
                            <strong>Experience has ended.</strong>
                          </>
                        )}
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className="not-live-page-col">
                      <p>Experience powered by DRAWBRIDGE LABS</p>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCardContent>
            </IonCard>
          </div>
        </div>
      )}
    </>
  );
};

export default ExperienceNotStarted;
