import { createContext, useContext } from "react";
import { Experience } from "../models/experience";
import { Tenant } from "../models/tenant";
import { ExperienceProduct } from "../models/experience-product";
import { Brand } from "../models/brand";
import { Set } from "../models/set";
import { ExperienceQuestion } from "../models/experience-question";
import { ExperienceVideo } from "../models/experience-video";
import { Event } from "../models/event";
import { CartProductVariant } from "../models/cart-product-variant";
import { ListProductVariant } from "../models/list-product-variant";
import { Store } from "../models/store";
import { UserAccount } from "../models/user-account";

export const ExperienceContext = createContext<{
  tenant: Tenant | undefined;
  brand: Brand | undefined;
  event: Event | undefined;
  experience: Experience | undefined;
  placementId: string | undefined;
  set: Set | undefined;
  experienceProducts: ExperienceProduct[];
  experienceQuestions: ExperienceQuestion[];
  experienceVideos: ExperienceVideo[];
  cartProductVariants: CartProductVariant[];
  listProductVariants: ListProductVariant[];
  stores: Store[];
  userAccount: UserAccount | undefined;
  experienceIsLoading: boolean;
}>({
  tenant: undefined,
  brand: undefined,
  event: undefined,
  experience: undefined,
  placementId: undefined,
  set: undefined,
  experienceProducts: [],
  experienceQuestions: [],
  experienceVideos: [],
  cartProductVariants: [],
  listProductVariants: [],
  stores: [],
  userAccount: undefined,
  experienceIsLoading: true,
});

export const useExperienceContext = () =>
  useContext<{
    tenant: Tenant | undefined;
    brand: Brand | undefined;
    event: Event | undefined;
    experience: Experience | undefined;
    placementId: string | undefined;
    set: Set | undefined;
    experienceProducts: ExperienceProduct[];
    experienceQuestions: ExperienceQuestion[];
    experienceVideos: ExperienceVideo[];
    cartProductVariants: CartProductVariant[];
    listProductVariants: ListProductVariant[];
    stores: Store[];
    userAccount: UserAccount | undefined;
    experienceIsLoading: boolean;
  }>(ExperienceContext);
