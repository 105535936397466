import { db } from '../firebase/firebase';
import { docData } from 'rxfire/firestore';
import { Observable, of } from 'rxjs';
import { doc } from 'firebase/firestore';
import { Experience } from '../models/experience';


function getExperienceById(id: string): Observable<Experience> {
  return docData(doc(db, `experiences/${id}`))
}

const ExperienceService = {
  getExperienceById
};

export default ExperienceService;