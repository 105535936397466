import { db } from '../firebase/firebase';
import { collectionData } from 'rxfire/firestore';
import { Observable, of } from 'rxjs';
import { collection, query, where } from 'firebase/firestore';
import { CartProductVariant } from '../models/cart-product-variant';


function getCartProductVariantsByUserAndEvent(tenantId?: string, userAccountId?: string, eventId?: string): Observable<CartProductVariant[]> {
  if (tenantId && userAccountId && eventId) {
    return collectionData(
      query(collection(db, `cartProductVariants`),
      where('tenantId', '==', tenantId), 
      where('userAccountId', '==', userAccountId),
      where('eventId', '==', eventId),
      where('deleted', '==', false)))
  } else {
    return of([])
  }
}

const CartProductVariantService = {
  getCartProductVariantsByUserAndEvent
};

export default CartProductVariantService;