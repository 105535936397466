import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

/**
 * Configure Firebase Environment based on domain
 */
var firebaseConfig: any = {};
var functionsDomain: string;
var tenantIdValue: string;

if (import.meta.env.REACT_APP_FIREBASE_KEY) {
  //Allow Environment File (.env) Override
  firebaseConfig = {
    apiKey: import.meta.env.REACT_APP_FIREBASE_KEY,
    authDomain: import.meta.env.REACT_APP_FIREBASE_DOMAIN,
    projectId: import.meta.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: import.meta.env.REACT_APP_MESSAGING_APP_ID,
    measurementId: import.meta.env.REACT_APP_MEASUREMENT_ID,
  };

  functionsDomain = String(import.meta.env.REACT_APP_FUNCTIONS_DOMAIN);
  tenantIdValue = String(import.meta.env.REACT_APP_TENANT_ID);
} else {
  const hostname = document.location.hostname;

  // set the correct environment configuration
  switch (hostname) {
    // drawbridge-production-aus (AUS Southeast 1)
    case "bridge.aus.dbxp.app":
      firebaseConfig = {
        apiKey: "AIzaSyAqloulEKoKb33CaDI1hGqOjerQxxk_pHk",
        authDomain: "drawbridge-production-aus.firebaseapp.com",
        projectId: "drawbridge-production-aus",
        storageBucket: "drawbridge-production-aus.appspot.com",
        messagingSenderId: "823184730809",
        appId: "1:823184730809:web:57299bebcbf1ce0c988968",
        measurementId: "G-MF25VBKJZ3",
      };
      functionsDomain =
        "australia-southeast1-drawbridge-production-aus.cloudfunctions.net";
      tenantIdValue = "Bridge-7nma1";
      break;

    // drawbridge-staging-us (US Central 1)
    case "bridge.staging.dbxp.app":
    case "fpd.bridge.dbxp.app":
    case "staging.bridge.dbxp.app":
      firebaseConfig = {
        apiKey: "AIzaSyDFFr-hFbtLvaRpj-qxYEDODzJHFidxoxQ",
        authDomain: "drawbridge-staging-us.firebaseapp.com",
        projectId: "drawbridge-staging-us",
        storageBucket: "drawbridge-staging-us.appspot.com",
        messagingSenderId: "865040534037",
        appId: "1:865040534037:web:ab78b3600c3d1ff6791c1c",
        measurementId: "G-XTTY50HB4L",
      };
      functionsDomain = "us-central1-drawbridge-staging-us.cloudfunctions.net";
      tenantIdValue = "Bridge-m3lzs";
      break;

    // drawbridge-production-1 (AUS Southeast 1)
    case "demo.au.dbxp.app":
      firebaseConfig = {
        apiKey: "AIzaSyDKj0-gcUrtv62cUAufSvE4xgKvzEePihQ",
        authDomain: "drawbridge-production-1.firebaseapp.com",
        projectId: "drawbridge-production-1",
        storageBucket: "drawbridge-production-1.appspot.com",
        messagingSenderId: "126606589431",
        appId: "1:126606589431:web:85f50cac7c3489c90702f2",
        measurementId: "G-KXS5D1CPPF",
      };
      functionsDomain =
        "australia-southeast1-drawbridge-production-1.cloudfunctions.net";
      tenantIdValue = "Demo-cmy37";
      break;

    // drawbridge-staging-1 (AUS Southeast 1)
    case "demo.staging-au.dbxp.app":
      firebaseConfig = {
        apiKey: "AIzaSyCdPxjSZtGfzd8YHTYnz4FXvfCs4awKi7w",
        authDomain: "drawbridge-staging-1.firebaseapp.com",
        projectId: "drawbridge-staging-1",
        storageBucket: "drawbridge-staging-1.appspot.com",
        messagingSenderId: "587934632475",
        appId: "1:587934632475:web:1fd0725efa908cc61386be",
        measurementId: "G-YJ89K0LJV5",
      };
      functionsDomain =
        "australia-southeast1-drawbridge-staging-1.cloudfunctions.net";
      tenantIdValue = "Demo-gpuec";
      break;

    // drawbridge-mik (AUS Southeast 1)
    case "mik.dbxp.app":
      firebaseConfig = {
        apiKey: "AIzaSyCn4iWHC0OCNOKSUIpwVLz6gGgTundC5_4",
        authDomain: "drawbridge-mik.firebaseapp.com",
        projectId: "drawbridge-mik",
        storageBucket: "drawbridge-mik.appspot.com",
        messagingSenderId: "813642010587",
        appId: "1:813642010587:web:06e29caf3b7aeb4d3221fe",
        measurementId: "G-RPPSJZ8188",
      };
      functionsDomain =
        "australia-southeast1-drawbridge-mik.cloudfunctions.net";
      tenantIdValue = "Made-In-Katana-5bc4z";
      break;

    // drawbridge-production-us (US Central 1)
    default:
      firebaseConfig = {
        apiKey: "AIzaSyBpk0GTdSimsJXrN2h8gXofQ-IQHYQjSNM",
        authDomain: "drawbridge-production-us.firebaseapp.com",
        projectId: "drawbridge-production-us",
        storageBucket: "drawbridge-production-us.appspot.com",
        messagingSenderId: "591343184756",
        appId: "1:591343184756:web:9fed943f45f2507908da21",
        measurementId: "G-6DSBQECYSW",
      };
      functionsDomain =
        "us-central1-drawbridge-production-us.cloudfunctions.net";
      tenantIdValue = "Bridge-cmsbj";
      break;
  }
}

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

const authWithTenant = getAuth(app);
authWithTenant.tenantId = tenantIdValue;
export const auth = authWithTenant;

export const db = getFirestore(app);
export const functionsUrl: string = `https://${functionsDomain}/v1`;
export const tenantId: string = tenantIdValue;
