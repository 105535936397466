import React, { useContext, useEffect, useRef, useState } from "react";
import {
  collection,
  doc,
  setDoc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../firebase/firebase";

import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
} from "@ionic/react";

import "./ProductInformation.css";
import {
  addCircle,
  addOutline,
  bookmarkOutline,
  cartOutline,
  checkmarkCircle,
  checkmarkOutline,
  closeCircle,
  closeOutline,
  removeCircle,
} from "ionicons/icons";
import { Experience } from "../../../models/experience";
import { CartProductVariant } from "../../../models/cart-product-variant";
import { Store } from "../../../models/store";
import { ProductVariant } from "../../../models/product-variant";
import { ListProductVariant } from "../../../models/list-product-variant";
import { ExperienceProduct } from "../../../models/experience-product";
import ProductVariantService from "../../../services/productVariant.service";
import GoogleAnalyticsService from "../../../services/googleAnalytics.service";
import UserAccountActionService from "../../../services/userAccountAction.service";
import { useAuthUserContext } from "../../../context/AuthUserContext";
import { useExperienceContext } from "../../../context/ExperienceContext";
import { User } from "firebase/auth";

interface ContainerProps {
  experienceProduct: ExperienceProduct;
  openBrowser(
    experience: Experience,
    placementId: string,
    store: Store,
    experienceProduct: ExperienceProduct,
    productVariant: ProductVariant,
    url: string,
    urlSupportsIFrameEmbed: boolean,
    authUser: User
  ): void;
  allowProductRemoval: boolean;
}

const ProductCard: React.FC<ContainerProps> = ({
  experienceProduct,
  openBrowser,
  allowProductRemoval,
}) => {
  // Context API
  const { authUser, authUserIsLoading } = useAuthUserContext();
  const {
    tenant,
    brand,
    event,
    experience,
    placementId,
    set,
    experienceProducts,
    experienceQuestions,
    experienceVideos,
    cartProductVariants,
    listProductVariants,
    stores,
    userAccount,
    experienceIsLoading,
  } = useExperienceContext();

  const [store, setStore] = useState<Store>();
  const [shoppingCart, setShoppingCart] = useState<any>();
  const [productVariants, setProductVariants] = useState<ProductVariant[]>();

  useEffect(() => {
    const getData = async () => {
      // set store that is related to the product
      const relatedStore = stores.find(
        (storeObj) => storeObj.id === experienceProduct.storeId
      );
      setStore(relatedStore);

      // TODO: Remove the alternative of querying product variants since they should be added to every experienceProduct going forward
      const productVariants: ProductVariant[] =
        experienceProduct.productVariants &&
        experienceProduct.productVariants.length > 0
          ? experienceProduct.productVariants
          : await ProductVariantService.getAllForProductId(
              experienceProduct.tenantId,
              experienceProduct.productId
            );

      setProductVariants(productVariants);

      let cartDictionary: any = {};
      for (const cartProductVariant of cartProductVariants) {
        if (
          cartDictionary &&
          cartProductVariant.productVariantId &&
          cartDictionary[String(cartProductVariant.productVariantId)]
        ) {
          cartDictionary[String(cartProductVariant.productVariantId)] =
            cartDictionary[String(cartProductVariant.productVariantId)] + 1;
        } else {
          cartDictionary[String(cartProductVariant.productVariantId)] = 1;
        }
      }

      let newShoppingCart: any = {};
      for (const cartProductVariant of cartProductVariants) {
        newShoppingCart[String(cartProductVariant.productVariantId)] =
          newShoppingCart &&
          newShoppingCart[String(cartProductVariant.productVariantId)]
            ? newShoppingCart[String(cartProductVariant.productVariantId)] + 1
            : 1;
      }

      setShoppingCart(newShoppingCart);

      return;
    };

    getData().catch((err) => {
      alert(err);
      throw err;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experienceProduct, cartProductVariants]);

  const modal = useRef<HTMLIonModalElement>(null);

  /**
   * Add To Cart
   * @param productVariant
   */
  const addOneProductVariantToCart = async (productVariant: ProductVariant) => {
    try {
      if (!authUser?.uid)
        throw new Error("Invalid user account. Please contact support.");
      if (!experience)
        throw new Error("Invalid experience provided. Please contact support.");
      if (!placementId)
        throw new Error("Invalid placement ID. Please contact support.");

      // Add product to cart
      const cartProductVariantId: string = doc(
        collection(db, "cartProductVariants")
      ).id;
      const cartProductVariant: CartProductVariant = {
        id: cartProductVariantId,
        tenantId: productVariant.tenantId,
        brandId: productVariant.brandId,
        eventId: experience.eventId,
        experienceId: experienceProduct.experienceId,
        placementId: placementId,
        productVariantId: productVariant.id,
        productId: productVariant.productId,
        userAccountId: authUser?.uid,
        createdDate: serverTimestamp(),
        createdByUserAccountId: authUser?.uid,
        deleted: false,
      };

      await setDoc(
        doc(db, `cartProductVariants/${cartProductVariantId}`),
        cartProductVariant
      );

      // analytics events
      if (store) {
        GoogleAnalyticsService.logItemInteraction(
          "add_to_cart",
          experience,
          placementId,
          store,
          experienceProduct,
          productVariant
        );
        await UserAccountActionService.logItemInteraction(
          "add_to_cart",
          experience,
          placementId,
          store,
          experienceProduct,
          productVariant,
          authUser
        );
      }
    } catch (err) {
      alert(err);
    }
  };

  /**
   * Remove from Cart
   * @param productVariant
   */
  const subtractOneProductVariantFromCart = async (
    productVariant: ProductVariant
  ) => {
    try {
      for (const cartProductVariant of cartProductVariants) {
        if (cartProductVariant.productVariantId === productVariant.id) {
          if (!authUser?.uid)
            throw new Error("Invalid user account. Please contact support.");
          if (!authUser?.uid)
            throw new Error("Invalid user account. Please contact support.");
          if (!experience)
            throw new Error(
              "Invalid experience provided. Please contact support."
            );
          if (!placementId)
            throw new Error("Invalid placement ID. Please contact support.");

          // Remove product from cart
          await updateDoc(
            doc(db, `cartProductVariants/${cartProductVariant.id}`),
            {
              updatedDate: serverTimestamp(),
              updatedByUserAccountId: authUser?.uid,
              deleted: true,
            }
          );

          // analytics events
          if (store) {
            GoogleAnalyticsService.logItemInteraction(
              "remove_from_cart",
              experience,
              placementId,
              store,
              experienceProduct,
              productVariant
            );
            await UserAccountActionService.logItemInteraction(
              "remove_from_cart",
              experience,
              placementId,
              store,
              experienceProduct,
              productVariant,
              authUser
            );
          }

          break;
        }
      }
    } catch (err) {
      alert(err);
    }
  };

  /**
   * Add to List
   * @param productVariant
   */
  const addOneProductVariantToList = async (productVariant: ProductVariant) => {
    try {
      if (!authUser?.uid)
        throw new Error("Invalid user account. Please contact support.");
      if (!authUser?.uid)
        throw new Error("Invalid user account. Please contact support.");
      if (!experience)
        throw new Error("Invalid experience provided. Please contact support.");
      if (!placementId)
        throw new Error("Invalid placement ID. Please contact support.");

      // Add product to list
      const listProductVariantId: string = doc(
        collection(db, "listProductVariants")
      ).id;
      const listProductVariant: ListProductVariant = {
        id: listProductVariantId,
        tenantId: experienceProduct.tenantId,
        brandId: experienceProduct.brandId,
        eventId: experience?.eventId,
        experienceId: experienceProduct.experienceId,
        placementId: placementId,
        productVariantId: productVariant.id,
        productId: productVariant.productId,
        userAccountId: authUser?.uid,
        createdDate: serverTimestamp(),
        createdByUserAccountId: authUser?.uid,
        deleted: false,
      };

      await setDoc(
        doc(db, `listProductVariants/${listProductVariantId}`),
        listProductVariant
      );

      // analytics events
      if (store) {
        GoogleAnalyticsService.logItemInteraction(
          "add_to_wishlist",
          experience,
          placementId,
          store,
          experienceProduct,
          productVariant
        );
        await UserAccountActionService.logItemInteraction(
          "add_to_wishlist",
          experience,
          placementId,
          store,
          experienceProduct,
          productVariant,
          authUser
        );
      }
    } catch (err) {
      alert(err);
    }
  };

  /**
   * Remove from List
   * @param productVariant
   */
  const subtractOneProductVariantFromList = async (
    productVariant: ProductVariant
  ) => {
    try {
      for (const listProductVariant of listProductVariants) {
        if (listProductVariant.productVariantId === productVariant.id) {
          if (!authUser?.uid)
            throw new Error("Invalid user account. Please contact support.");
          if (!authUser?.uid)
            throw new Error("Invalid user account. Please contact support.");
          if (!experience)
            throw new Error(
              "Invalid experience provided. Please contact support."
            );
          if (!placementId)
            throw new Error("Invalid placement ID. Please contact support.");

          // Remove product from list
          await updateDoc(
            doc(db, `listProductVariants/${listProductVariant.id}`),
            {
              updatedDate: serverTimestamp(),
              updatedByUserAccountId: authUser?.uid,
              deleted: true,
            }
          );

          // analytics events
          if (store) {
            GoogleAnalyticsService.logItemInteraction(
              "remove_from_wishlist",
              experience,
              placementId,
              store,
              experienceProduct,
              productVariant
            );
            await UserAccountActionService.logItemInteraction(
              "remove_from_wishlist",
              experience,
              placementId,
              store,
              experienceProduct,
              productVariant,
              authUser
            );
          }
          break;
        }
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      {productVariants && experience && placementId && authUser && (
        <IonGrid>
          <IonRow>
            <IonCol size="6">
              {/* No shopping cart, open iFrame when clicking on product image */}
              {(store &&
                store.supportsExperienceShoppingCart === false &&
                productVariants &&
                productVariants[0]?.productVariantUrlInStore && (
                  <img
                    src={experienceProduct.productImageUrl}
                    className="product-image"
                    onClick={() => {
                      openBrowser(
                        experience,
                        placementId,
                        store,
                        experienceProduct,
                        productVariants[0],
                        String(productVariants[0].productVariantUrlInStore),
                        Boolean(store.storeWebsiteSupportsIFrameEmbed),
                        authUser
                      );
                    }}
                  />
                )) || (
                <img
                  src={experienceProduct.productImageUrl}
                  className="product-image"
                />
              )}
            </IonCol>
            <IonCol size="6">
              {store?.storeImageUrl && (
                <img src={store?.storeImageUrl} className="store-image" />
              )}
              {!store?.storeImageUrl && (
                <div className="store-name-container">
                  <div className="store-name">{store?.storeName}</div>
                </div>
              )}

              {productVariants.map(function (productVariant, index) {
                return (
                  <IonRow key={index}>
                    {/* Show Product Variant descripiton and price if multiple variants exist */}
                    {productVariants.length > 1 && (
                      <IonCol size="12">
                        <IonLabel className="ion-text-wrap">
                          <h2>{productVariant.productVariantDescription}</h2>
                          <p>
                            ${productVariant.productVariantPrice}{" "}
                            {store?.storeCurrency}
                          </p>
                        </IonLabel>
                      </IonCol>
                    )}

                    {/* Add to cart */}
                    {store && store.supportsExperienceShoppingCart === true && (
                      <IonCol size="12" style={{ padding: "0" }}>
                        <IonButton
                          fill="clear"
                          shape="round"
                          onClick={async () => {
                            await subtractOneProductVariantFromCart(
                              productVariant
                            );
                          }}
                        >
                          <IonIcon icon={removeCircle}></IonIcon>
                        </IonButton>
                        <IonButton fill="clear" color="dark">
                          {shoppingCart &&
                          shoppingCart[String(productVariant.id)]
                            ? shoppingCart[String(productVariant.id)]
                            : 0}
                        </IonButton>
                        <IonButton
                          fill="clear"
                          shape="round"
                          onClick={async () => {
                            await addOneProductVariantToCart(productVariant);
                          }}
                        >
                          <IonIcon icon={addCircle}></IonIcon>
                        </IonButton>
                      </IonCol>
                    )}

                    {/* No shopping cart. Open iFrame */}
                    {store &&
                      store.supportsExperienceShoppingCart === false &&
                      productVariant.productVariantUrlInStore && (
                        <IonCol size="12" style={{ padding: "0" }}>
                          <IonButton
                            onClick={() => {
                              openBrowser(
                                experience,
                                placementId,
                                store,
                                experienceProduct,
                                productVariant,
                                String(productVariant.productVariantUrlInStore),
                                Boolean(store.storeWebsiteSupportsIFrameEmbed),
                                authUser
                              );
                            }}
                            color="primary"
                            expand="block"
                            className="pulse-button"
                          >
                            {store.viewProductButtonText &&
                            store.viewProductButtonText.length > 0
                              ? store.viewProductButtonText
                              : "Explore Product"}
                          </IonButton>
                        </IonCol>
                      )}

                    {/* Shopping List */}
                    {store && store.supportsExperienceShoppingList === true && (
                      <IonCol size="12" style={{ padding: "0" }}>
                        {!listProductVariants.find(
                          (listProductVariant) =>
                            productVariant.id ===
                            listProductVariant.productVariantId
                        ) && (
                          <IonButton
                            color="success"
                            fill="solid"
                            expand="block"
                            onClick={async () => {
                              await addOneProductVariantToList(productVariant);
                            }}
                          >
                            Save to list
                            <IonIcon
                              slot="end"
                              icon={bookmarkOutline}
                            ></IonIcon>
                          </IonButton>
                        )}
                        {listProductVariants.find(
                          (listProductVariant) =>
                            productVariant.id ===
                            listProductVariant.productVariantId
                        ) && (
                          <>
                            {allowProductRemoval && (
                              <IonButton
                                color="success"
                                fill="outline"
                                expand="block"
                                onClick={async () => {
                                  await subtractOneProductVariantFromList(
                                    productVariant
                                  );
                                }}
                              >
                                Remove save
                                <IonIcon
                                  slot="end"
                                  icon={closeCircle}
                                ></IonIcon>
                              </IonButton>
                            )}

                            {!allowProductRemoval && (
                              <IonButton
                                color="success"
                                fill="outline"
                                disabled
                                expand="block"
                              >
                                Saved
                                <IonIcon
                                  slot="end"
                                  icon={checkmarkCircle}
                                ></IonIcon>
                              </IonButton>
                            )}
                          </>
                        )}
                      </IonCol>
                    )}
                  </IonRow>
                );
              })}
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
    </>
  );
};

export default ProductCard;
