import { db } from "../firebase/firebase";
import { docData } from "rxfire/firestore";
import { Observable, of } from "rxjs";
import { doc } from "firebase/firestore";
import { TestAccess } from "../models/test-access";

function getTestAccessById(id: string): Observable<TestAccess> {
  return docData(doc(db, `testAccesses/${id}`));
}

const TestAccessService = {
  getTestAccessById,
};

export default TestAccessService;
