import { db } from '../firebase/firebase';
import { docData } from 'rxfire/firestore';
import { Observable, of } from 'rxjs';
import { doc } from 'firebase/firestore';
import { Event } from '../models/event';


function getEventById(id?: string): Observable<Event | undefined> {
  if (id) {
    return docData(doc(db, `events/${id}`))
  } else {
    return of(undefined)
  }
}

const EventService = {
  getEventById
};

export default EventService;