import './NotFound.css';
import { IonContent, IonPage } from '@ionic/react';
import InvalidPage from '../../components/shared/InvalidPage/InvalidPage';

const NotFound: React.FC = () => {

  return (
    <IonPage>
      <IonContent fullscreen>
        
        <InvalidPage />

      </IonContent>
    </IonPage>
  );
};

export default NotFound;
