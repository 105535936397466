import { Suspense, lazy } from "react";
import "./SetLoader.css";
import { Experience } from "../../../models/experience";
import { Set } from "../../../models/set";
import { Brand } from "../../../models/brand";
import { IonLoading } from "@ionic/react";
import { ExperienceVideo } from "../../../models/experience-video";
import { ExperienceQuestion } from "../../../models/experience-question";
import { ExperienceProduct } from "../../../models/experience-product";
import { User } from "firebase/auth";
import { useAuthUserContext } from "../../../context/AuthUserContext";
import { useExperienceContext } from "../../../context/ExperienceContext";

const setComponents: any = {
  PetClinic: lazy(
    () => import("../../sets/pet-clinic/ImmersiveSpace/ImmersiveSpace")
  ),
  OneBedroomHouse: lazy(
    () => import("../../sets/one-bedroom-house/ImmersiveSpace/ImmersiveSpace")
  ),
};

interface ContainerProps {
  userViewsProduct(experienceProduct: ExperienceProduct): Promise<void>;
  productModalIsOpen: boolean;
  userOpensMenu(tabName?: "saved" | "products" | undefined): void;
}

const SetLoader: React.FC<ContainerProps> = ({
  userViewsProduct,
  productModalIsOpen,
  userOpensMenu,
}) => {
  // Context API
  const { authUser, authUserIsLoading } = useAuthUserContext();
  const {
    tenant,
    brand,
    event,
    experience,
    placementId,
    set,
    experienceProducts,
    experienceQuestions,
    experienceVideos,
    cartProductVariants,
    listProductVariants,
    stores,
    userAccount,
    experienceIsLoading,
  } = useExperienceContext();

  // TODO: TEMPORARY OVERRIDE TO REDUCE LOADING TIME.
  // Set the set component name to lazy load the proper component.
  // if (!set || !set.setComponentName)
  //   return <div>MISSING SET COMPONENT NAME.</div>;
  // const SetComponent = setComponents[set.setComponentName];
  const SetComponent = setComponents["OneBedroomHouse"];

  if (!SetComponent) return <div>MISSING SPECIFIED SET COMPONENT.</div>;

  return (
    // <Suspense fallback={<IonLoading isOpen={true}></IonLoading>}>
    // <Suspense>
    <SetComponent
      experience={experience}
      brand={brand}
      experienceProducts={experienceProducts}
      set={set}
      experienceVideos={experienceVideos}
      experienceQuestions={experienceQuestions}
      userViewsProduct={userViewsProduct}
      productModalIsOpen={productModalIsOpen}
      userOpensMenu={userOpensMenu}
      authUser={authUser}
    />
    // </Suspense>
  );
};

export default SetLoader;
