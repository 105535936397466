import React, { useEffect, useRef } from "react";

import {
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonModal,
  IonToolbar,
} from "@ionic/react";

import "./BrowserModal.css";
import { arrowBackOutline } from "ionicons/icons";

interface ContainerProps {
  browserModalUrl: string;
  browserModalIsOpen: boolean;
  setBrowserModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const BrowserModal: React.FC<ContainerProps> = ({
  browserModalUrl,
  browserModalIsOpen,
  setBrowserModalIsOpen,
}) => {
  useEffect(() => {
    const getData = async () => {
      return;
    };

    getData().catch((err) => {
      alert(err);
      throw err;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [browserModalUrl]);

  const modal = useRef<HTMLIonModalElement>(null);

  const onDismissModal = () => {
    setBrowserModalIsOpen(false);
  };

  return (
    <div>
      <IonModal
        className="fullwidth"
        ref={modal}
        isOpen={browserModalIsOpen}
        onDidDismiss={onDismissModal}
        initialBreakpoint={1}
        breakpoints={[0, 1]}
        showBackdrop={false}
        animated={false}
      >
        <IonToolbar color="dark">
          {/* <IonTitle>Visiting Website</IonTitle> */}
          <IonButtons slot="start">
            <IonButton id="open-modal" onClick={onDismissModal}>
              <IonIcon icon={arrowBackOutline} slot="start"></IonIcon>
              Back to Experience
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonContent color="light">
          <iframe src={browserModalUrl} title="2d-browser">
            Unable to load content.
          </iframe>
        </IonContent>
      </IonModal>
    </div>
  );
};

export default BrowserModal;
