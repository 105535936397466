import { db } from '../firebase/firebase';
import { collectionData } from 'rxfire/firestore';
import { Observable, of } from 'rxjs';
import { collection, query, where } from 'firebase/firestore';
import { Store } from '../models/store';


function getStoresByIds(tenantId?: string, storeIds?: string[]): Observable<Store[]> {
  if (tenantId && storeIds && storeIds.length > 0) {
    return collectionData(
      query(collection(db, 'stores'),
      where('tenantId', '==', tenantId),
      where('id', 'in', storeIds),
      where('deleted', '==', false)))
  } else {
    return of([])
  }
}

const StoreService = {
  getStoresByIds
};

export default StoreService;