import { analytics } from "../firebase/firebase";
import { logEvent } from "firebase/analytics";
import { ExperienceProduct } from "../models/experience-product";
import { ProductVariant } from "../models/product-variant";
import { Store } from "../models/store";
import { Experience } from "../models/experience";

function logItemInteraction(
  eventName:
    | "view_item_website"
    | "view_item"
    | "add_to_cart"
    | "remove_from_cart"
    | "add_to_wishlist"
    | "remove_from_wishlist"
    | string,
  experience: Experience,
  placementId: string,
  store: Store,
  experienceProduct: ExperienceProduct,
  productVariant: ProductVariant
): void {
  logEvent(analytics, eventName, {
    tenant_id: experience?.tenantId || null,
    brand_id: experience?.brandId || null,
    event_id: experience?.eventId || null,
    experience_id: experience.id
      ? `${experience.id} - ${experience.experienceName}`.slice(0, 99)
      : null,
    placement_id: placementId || null,
    product_id: experienceProduct.productId
      ? `${experienceProduct.productId} - ${experienceProduct.productName}`.slice(
          0,
          99
        )
      : null,
    store_id: store.id ? `${store.id} - ${store.storeName}`.slice(0, 99) : null,
    currency: store?.storeCurrency || null,
    value: productVariant?.productVariantPrice || 0,
    items: [
      {
        item_id: experienceProduct?.productId,
        item_name: `${experienceProduct?.productName} - ${productVariant?.productVariantDescription}`,
        price: productVariant?.productVariantPrice || 0,
        quantity: 1,
      },
    ],
  });
  console.log("gaEvent", eventName);
}

function logGeneralInteraction(
  eventName:
    | "grant_device_orientation_permission"
    | "deny_device_orientation_permission"
    | "host_video_ended"
    | "host_video_started"
    | "view_welcome_screen"
    | "click_button_to_enter",
  experience: Experience | undefined
): void {
  logEvent(analytics, eventName, {
    tenant_id: experience?.tenantId || null,
    brand_id: experience?.brandId || null,
    event_id: experience?.eventId || null,
    experience_id: experience?.id
      ? `${experience.id} - ${experience.experienceName}`.slice(0, 99)
      : null,
  });
  console.log("gaEvent", eventName);
}

const GoogleAnalyticsService = {
  logItemInteraction,
  logGeneralInteraction,
};

export default GoogleAnalyticsService;
