import React, { useEffect, useRef, useState } from "react";

import "./BrowserTab.css";
import { IonAlert } from "@ionic/react";

interface ContainerProps {
  browserUrl: string;
  browserIsOpen: boolean;
  setBrowserIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const BrowserTab: React.FC<ContainerProps> = ({
  browserUrl,
  browserIsOpen,
  setBrowserIsOpen,
}) => {
  const [showAlert, setShowAlert] = useState<boolean>(false);

  useEffect(() => {
    if (browserIsOpen) {
      setBrowserIsOpen(false);
      setShowAlert(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [browserUrl, browserIsOpen]);

  function openBrowserTab(url: string) {
    window.open(url, "_blank");
    setBrowserIsOpen(false);
    setShowAlert(false);
  }

  return (
    <>
      <IonAlert
        isOpen={showAlert}
        header="Visit Website"
        subHeader="You are about to visit an external website."
        message="Come back to this tab to continue your experience."
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
          },
          {
            text: "OK",
            role: "confirm",
            handler: () => {
              openBrowserTab(browserUrl);
            },
          },
        ]}
        onDidDismiss={() => {
          setBrowserIsOpen(false);
          setShowAlert(false);
        }}
      ></IonAlert>
    </>
  );
};

export default BrowserTab;
