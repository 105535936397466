import { createContext, useContext } from "react";

export const AnalyticsContext = createContext<{
  logViewWelcomeScreen: () => void;
  logHostVideoHasStarted: () => void;
  logClickButtonToEnter: () => void;
}>({
  logViewWelcomeScreen: () => {},
  logHostVideoHasStarted: () => {},
  logClickButtonToEnter: () => {},
});

export const useAnalyticsContext = () =>
  useContext<{
    logViewWelcomeScreen: () => void;
    logHostVideoHasStarted: () => void;
    logClickButtonToEnter: () => void;
  }>(AnalyticsContext);
