import {
  onAuthStateChanged,
  signInAnonymously,
  User,
  UserCredential,
} from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../firebase/firebase";
import UserAccountService from "../services/userAccount.service";

const AuthUserContext = createContext<{
  authUser: User | undefined;
  authUserIsLoading: boolean;
}>({
  authUser: undefined,
  authUserIsLoading: true,
});

export default function useFirebaseAuth() {
  const [authUser, setAuthUser] = useState<User | undefined>(undefined);
  const [authUserIsLoading, setAuthIsLoading] = useState(true);

  const authStateChanged = async (user: User | null) => {
    setAuthIsLoading(true);

    if (!user) {
      // Anonymously sign-in user for tenant
      const userCredential: UserCredential = await signInAnonymously(auth);

      // Create User account
      await UserAccountService.createUserAccount(
        String(userCredential.user.tenantId),
        userCredential.user
      ).catch((err) => {
        console.error(err);
        alert(err);
      });

      setAuthIsLoading(false);
      return;
    }

    setAuthUser(user);

    setAuthIsLoading(false);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, authStateChanged);
    return () => unsubscribe();
  }, []);

  return {
    authUser,
    authUserIsLoading,
  };
}

export function AuthUserProvider({ children }: any) {
  const auth = useFirebaseAuth();
  return (
    <AuthUserContext.Provider value={auth}>{children}</AuthUserContext.Provider>
  );
}

export const useAuthUserContext = () =>
  useContext<{
    authUser: User | undefined;
    authUserIsLoading: boolean;
  }>(AuthUserContext);
