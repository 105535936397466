import "./Experience.css";
import {
  IonButton,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonLoading,
  IonPage,
} from "@ionic/react";
import { User } from "firebase/auth";
import { useEffect, useState } from "react";

import { tenantId } from "../../firebase/firebase";

import { Helmet } from "react-helmet";

import {
  combineLatest,
  map,
  Subscription,
  switchMap,
  firstValueFrom,
} from "rxjs";
import { useParams } from "react-router";
import ProductModal from "../../components/shared/ProductModal/ProductModal";
import BrowserModal from "../../components/shared/BrowserModal/BrowserModal";
import { Tenant } from "../../models/tenant";
import { Experience } from "../../models/experience";
import { UserAccount } from "../../models/user-account";
import { useSnapshot } from "valtio";
import { ContainerProps, useGLTF } from "@react-three/drei";
import { Event as EventInterface } from "../../models/event";
import { Store } from "../../models/store";
import { Set } from "../../models/set";
import { CartProductVariant } from "../../models/cart-product-variant";
import { helpOutline, menuOutline } from "ionicons/icons";
import { ListProductVariant } from "../../models/list-product-variant";
import QuestionModal from "../../components/shared/QuestionModal/QuestionModal";
import UserRegistrationModal from "../../components/shared/UserRegistrationModal/UserRegistrationModal";
import { Brand } from "../../models/brand";
import BrandService from "../../services/brand.service";
import ExperienceService from "../../services/experience.service";
import SetService from "../../services/set.service";
import UserAccountService from "../../services/userAccount.service";
import EventService from "../../services/event.service";
import CartProductVariantService from "../../services/cartProductVariant.service";
import ListProductVariantService from "../../services/listProductVariant.service";
import TenantService from "../../services/tenant.service";
import StoreService from "../../services/store.service";
import UserAccountActionService from "../../services/userAccountAction.service";
import SetLoader from "../../components/shared/SetLoader/SetLoader";
import ExperienceQuestionService from "../../services/experience-question.service";
import ExperienceVideoService from "../../services/experience-video.service";
import ExperienceProductService from "../../services/experience-product.service";
import { ExperienceVideo } from "../../models/experience-video";
import { ExperienceQuestion } from "../../models/experience-question";
import { ExperienceProduct } from "../../models/experience-product";
import MenuModal from "../../components/shared/MenuModal/MenuModal";
import GoogleAnalyticsService from "../../services/googleAnalytics.service";
import { ProductVariant } from "../../models/product-variant";
import BrowserTab from "../../components/shared/BrowserTab/BrowserTab";
import ExperienceNotLive from "../../components/shared/ExperienceNotLive/ExperienceNotLive";
import { Timestamp } from "firebase/firestore";
import { isPlatform } from "@ionic/react";
import QRCodePage from "../../components/shared/QRCodePage/QRCodePage";
import TestAccessService from "../../services/test-access.service";
import { TestAccess } from "../../models/test-access";
import { useAuthUserContext } from "../../context/AuthUserContext";
import { ExperienceContext } from "../../context/ExperienceContext";
import PreExperienceLoader from "../../components/shared/PreExperienceLoader/PreExperienceLoader";
import { AnalyticsContext } from "../../context/AnalyticsContext";

const ExperienceComponent: React.FC = () => {
  // Auth User Context API Data
  const { authUser, authUserIsLoading } = useAuthUserContext();

  /**
   * Experience Data for Context API
   *  */
  const [experience, setExperience] = useState<Experience>();
  const [tenant, setTenant] = useState<Tenant>();
  const [brand, setBrand] = useState<Brand>();
  const [event, setEvent] = useState<EventInterface>();
  const [placementId, setPlacementId] = useState<string>();
  const [set, setSet] = useState<Set>();
  const [experienceProducts, setExperienceProducts] = useState<
    ExperienceProduct[]
  >([]);
  const [experienceQuestions, setExperienceQuestions] = useState<
    ExperienceQuestion[]
  >([]);
  const [experienceVideos, setExperienceVideos] = useState<ExperienceVideo[]>(
    []
  );
  const [cartProductVariants, setCartProductVariants] = useState<
    CartProductVariant[]
  >([]);
  const [listProductVariants, setListProductVariants] = useState<
    ListProductVariant[]
  >([]);
  const [stores, setStores] = useState<Store[]>([]);
  const [userAccount, setUserAccount] = useState<UserAccount>();
  const [experienceIsLoading, setExperienceIsLoading] = useState(true);

  // used to determine if the system has logged the view of the experience
  const [loggedViewExperience, setLoggedViewExperience] =
    useState<boolean>(false);

  // const [useShoppingCart, setUseShoppingCart] = useState<boolean>(false);
  // const [useShoppingList, setUseShoppingList] = useState<boolean>(false);

  // used to determine if the experience can be viewed based on date restrictions or override code
  const [canViewExperience, setCanViewExperience] = useState<boolean>(false);

  /**
   * Modal States
   */
  const [userRegistrationModalIsOpen, setUserRegistrationModalIsOpen] =
    useState<boolean>(false);
  const [menuModalIsOpen, setMenuModalIsOpen] = useState<boolean>(false);
  const [menuModalTabName, setMenuModalTabName] = useState<
    "saved" | "products" | undefined
  >();
  // const [shoppingCartModalIsOpen, setShoppingCartModalIsOpen] = useState<boolean>(false);
  // const [shoppingListModalIsOpen, setShoppingListModalIsOpen] = useState<boolean>(false);
  const [productModalIsOpen, setProductModalIsOpen] = useState<boolean>(false);
  const [productModalExperienceProduct, setProductModalExperienceProduct] =
    useState<ExperienceProduct>();
  const [questionModalIsOpen, setQuestionModalIsOpen] =
    useState<boolean>(false);
  const [browserModalIsOpen, setBrowserModalIsOpen] = useState<boolean>(false);
  const [browserIsOpen, setBrowserIsOpen] = useState<boolean>(false);
  const [browserUrl, setBrowserUrl] = useState<string>();

  const [isDesktop, setIsDesktop] = useState<boolean>();

  const params: any = useParams();

  // Handle Platform Detection
  useEffect(() => {
    // Check if user is using a desktop
    setIsDesktop(isPlatform("desktop"));
  }, []);

  // Handle Experience and Placement
  useEffect(() => {
    const experienceId = params.experienceId;
    const testAccessId = params.testAccessId; // used to override the date restrictions on the experience for testing production experiences
    const placementId = params.placementId || `testAccessId-${testAccessId}`;
    setPlacementId(placementId);

    let subscription: Subscription;
    subscription = ExperienceService.getExperienceById(experienceId).subscribe({
      next: async (experience) => {
        setExperience(experience);

        // Determine if the experience can be viewed
        if (testAccessId) {
          // check if test access code is valid
          const testAccess: TestAccess = await firstValueFrom(
            TestAccessService.getTestAccessById(testAccessId)
          );

          if (
            testAccess &&
            testAccess.testAccessStartDate &&
            (testAccess.testAccessStartDate as Timestamp).toMillis() <
              Date.now() &&
            testAccess.testAccessEndDate &&
            (testAccess.testAccessEndDate as Timestamp).toMillis() > Date.now()
          ) {
            // current date is between start and end date
            setCanViewExperience(true);
          } else {
            setCanViewExperience(false);
          }
        } else if (
          experience &&
          experience.experienceStartDate &&
          (experience.experienceStartDate as Timestamp).toMillis() <
            Date.now() &&
          experience.experienceEndDate &&
          (experience.experienceEndDate as Timestamp).toMillis() > Date.now()
        ) {
          // current date is between start and end date
          setCanViewExperience(true);
        } else {
          setCanViewExperience(false);
        }
      },
      error: (err) => {
        console.error(err);
        alert(err);
        throw err;
      },
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    // TenantId should be provided config based on hostname. Throw error immediately if non-existant so development can be alerted of a config issue.
    if (!tenantId) throw new Error("No Tenant Provided. Contact Support.");

    // Check if the user is authenticated and experience data exists before querying for experience metadata
    if (!authUser || !experience || !tenantId) return;
    let subscription: Subscription;
    subscription = combineLatest([TenantService.getTenantById(tenantId)])
      .pipe(
        switchMap(([tenant]) => {
          return combineLatest([
            BrandService.getBrandById(experience.brandId),
            SetService.getSetById(experience.setId),
            ExperienceQuestionService.getAllByExperience(experience),
            ExperienceVideoService.getAllByExperience(experience),
            ExperienceProductService.getAllByExperience(experience),
            EventService.getEventById(experience.eventId),
            CartProductVariantService.getCartProductVariantsByUserAndEvent(
              tenant.id,
              authUser.uid,
              experience.eventId
            ),
            ListProductVariantService.getListProductVariantsByUserAndEvent(
              tenant.id,
              authUser.uid,
              experience.eventId
            ),
          ]).pipe(
            switchMap(
              ([
                brand,
                set,
                experienceQuestions,
                experienceVideos,
                experienceProducts,
                event,
                cartProductVariants,
                listProductVariants,
              ]) => {
                return combineLatest([
                  StoreService.getStoresByIds(tenant.id, event?.storeIds),
                ]).pipe(
                  map(([stores]) => ({
                    tenant,
                    experience,
                    experienceProducts,
                    brand,
                    set,
                    experienceQuestions,
                    experienceVideos,
                    event,
                    cartProductVariants,
                    listProductVariants,
                    stores,
                  }))
                );
              }
            )
          );
        })
      )
      .subscribe({
        next: async ({
          tenant,
          experience,
          experienceProducts,
          brand,
          set,
          experienceQuestions,
          experienceVideos,
          event,
          cartProductVariants,
          listProductVariants,
          stores,
        }) => {
          setTenant(tenant);
          setExperience(experience);
          setBrand(brand);
          setExperienceProducts(experienceProducts);
          setCartProductVariants(cartProductVariants);
          setListProductVariants(listProductVariants);
          setSet(set);
          setEvent(event);
          setStores(stores);
          setExperienceVideos(experienceVideos);
          setExperienceQuestions(experienceQuestions);

          // // preload product models and images
          // for (const product of experienceProducts) {
          //   if (product.productModelUrl)
          //     useGLTF.preload(product.productModelUrl);
          //   if (product.productImageUrl)
          //     new Image().src = product.productImageUrl;
          // }

          // Determine if any stores expect the use of the shopping cart
          // let useShoppingCartCheck: boolean = false;
          // for (const store of stores) {
          //   if (store.supportsExperienceShoppingCart) {
          //     useShoppingCartCheck = true;
          //     break;
          //   }
          // }
          // setUseShoppingCart(useShoppingCartCheck);

          // Determine if any stores expect the use of the shopping list
          // let useShoppingListCheck: boolean = false;
          // for (const store of stores) {
          //   if (store.supportsExperienceShoppingList) {
          //     useShoppingListCheck = true;
          //     break;
          //   }
          // }
          // setUseShoppingList(useShoppingListCheck);

          setExperienceIsLoading(false);
        },
        error: (err) => {
          console.error(err);
          alert(err);
          throw err;
        },
      });

    return () => subscription.unsubscribe();
  }, [authUser, experience]);

  /**
   * Log User View Experience
   */
  useEffect(() => {
    if (!loggedViewExperience && authUser && experience && placementId) {
      setLoggedViewExperience(true);
      UserAccountActionService.logViewExperience(
        experience,
        placementId,
        authUser
      ).catch((err) => {
        console.error(err);
      });
    }
    return;
  }, [loggedViewExperience, experience, placementId, authUser]);

  /**
   * Log User Location
   */
  useEffect(() => {
    async function logUserAccountLocation() {
      if (authUser && tenantId) {
        await UserAccountService.setUserAccountLocation(tenantId, authUser);
      }
      return;
    }

    logUserAccountLocation().catch((err) => {
      console.error(err);
    });
  }, [authUser, tenantId]);

  // Provides a product-specific modal to the user
  async function userViewsProduct(experienceProduct: ExperienceProduct) {
    try {
      if (experienceProduct) {
        // close product modal if it is open
        if (productModalIsOpen) setProductModalIsOpen(false);

        setProductModalExperienceProduct(experienceProduct);

        // open new product modal
        setProductModalIsOpen(true);

        console.log(
          "userViewsProduct function called with Product ID: ",
          experienceProduct.id
        );
      } else {
        console.log(
          "userViewsProduct function called with no product provided to view"
        );
      }
    } catch (err) {
      alert(err);
      throw err;
    }
  }

  // Provides a product-specific modal to the user
  async function userOpensMenu(tabName?: "saved" | "products" | undefined) {
    try {
      // open menu modal
      setMenuModalTabName(tabName);
      setMenuModalIsOpen(true);
    } catch (err) {
      alert(err);
      throw err;
    }
  }

  // Provides a browser modal to the user
  const openBrowser = (
    experience: Experience,
    placementId: string,
    store: Store,
    experienceProduct: ExperienceProduct,
    productVariant: ProductVariant,
    url: string,
    urlSupportsIFrameEmbed: boolean,
    authUser: User
  ) => {
    // Log Google Analytics
    GoogleAnalyticsService.logItemInteraction(
      "view_item_website",
      experience,
      placementId,
      store,
      experienceProduct,
      productVariant
    );

    // Log User Account Action
    UserAccountActionService.logItemInteraction(
      "view_item_website",
      experience,
      placementId,
      store,
      experienceProduct,
      productVariant,
      authUser
    ).catch((err) => console.error(err));

    setBrowserUrl(url);

    if (urlSupportsIFrameEmbed) {
      setBrowserModalIsOpen(true);
    } else {
      window.open(url, "_blank");
      // TODO: Disabled browser opening alert for now.
      // setBrowserIsOpen(true);
    }
  };
  // Log User Account Action and Google Analytics when the video was watched and ends
  const logViewWelcomeScreen = () => {
    if (experience && authUser) {
      // Log Google Analytics
      GoogleAnalyticsService.logGeneralInteraction(
        "view_welcome_screen",
        experience
      );

      // Log User Account Action
      UserAccountActionService.logGeneralInteraction(
        "view_welcome_screen",
        experience,
        authUser
      ).catch(console.error);
    } else {
      console.error(
        "Experience or Auth User not defined to logViewWelcomeScreen."
      );
    }
  };

  const logClickButtonToEnter = () => {
    if (experience && authUser) {
      // Log Google Analytics
      GoogleAnalyticsService.logGeneralInteraction(
        "click_button_to_enter",
        experience
      );

      // Log User Account Action
      UserAccountActionService.logGeneralInteraction(
        "click_button_to_enter",
        experience,
        authUser
      ).catch(console.error);
    } else {
      console.error(
        "Experience or Auth User not defined to logClickButtonToEnter."
      );
    }
  };

  // Log User Account Action and Google Analytics when the video starts
  const logHostVideoHasStarted = () => {
    if (experience && authUser) {
      // Log Google Analytics
      GoogleAnalyticsService.logGeneralInteraction(
        "host_video_started",
        experience
      );

      // Log User Account Action
      UserAccountActionService.logGeneralInteraction(
        "host_video_started",
        experience,
        authUser
      ).catch(console.error);
    } else {
      console.error(
        "Experience or Auth User not defined to logHostVideoHasStarted."
      );
    }
  };

  return (
    <ExperienceContext.Provider
      value={{
        tenant,
        brand,
        event,
        experience,
        placementId,
        set,
        experienceProducts,
        experienceQuestions,
        experienceVideos,
        cartProductVariants,
        listProductVariants,
        stores,
        userAccount,
        experienceIsLoading,
      }}
    >
      <AnalyticsContext.Provider
        value={{
          logViewWelcomeScreen,
          logHostVideoHasStarted,
          logClickButtonToEnter,
        }}
      >
        <IonPage>
          <IonContent fullscreen>
            <Helmet>
              <title>{`Space Invaders - Shop the Home`}</title>
              <meta
                name="description"
                content="Check out this 3D immersive shopping experience!"
              />
              <meta
                property="og:title"
                content={`Space Invaders - Shop the Home`}
              />
              <meta
                property="og:description"
                content="Check out this 3D immersive shopping experience!"
              />
              <meta
                property="og:image"
                content="/assets/textures/one-bedroom-house/bgblurreddesktop.webp"
              />

              <meta property="og:url" content={window.location.href} />
            </Helmet>

            {/*/!* Loading spinner until event data is provided *!/*/}
            {!experience || !authUser ? (
              <PreExperienceLoader></PreExperienceLoader>
            ) : (
              <>
                {canViewExperience ? (
                  <>
                    {isDesktop ? (
                      // Show QR Code page if using desktop computer
                      <QRCodePage />
                    ) : (
                      <>
                        {/* Load 3D set component for experience */}
                        {/* TODO: TEMPORARY OVERRIDE TO REDUCE LOADING TIME. */}
                        {/* {tenant &&
                        brand &&
                        event &&
                        experience &&
                        experienceProducts &&
                        set && 
                      authUser && ( */}
                        <SetLoader
                          userViewsProduct={userViewsProduct}
                          productModalIsOpen={productModalIsOpen}
                          userOpensMenu={userOpensMenu}
                        />
                        {/* )} */}

                        {/* BOTTOM BUTTONS */}
                        <IonFab
                          slot="fixed"
                          horizontal="center"
                          vertical="bottom"
                        >
                          {/* Menu Modal Button */}
                          {/* <IonButton
                          onClick={() => userOpensMenu()}
                          className="inline-fab-button"
                          id="menu-modal-button"
                          color={"light"}
                        >
                          <IonIcon icon={menuOutline}></IonIcon>
                        </IonButton> */}

                          {/* Shopping Cart Button */}
                          {/* {useShoppingCart &&
                          <IonFabButton onClick={() => setShoppingCartModalIsOpen(true)} className='inline-fab-button' id="shopping-cart-modal-button">
                            <IonIcon icon={cartOutline}></IonIcon>
                          </IonFabButton>
                        } */}

                          {/* Shopping List Button */}
                          {/* {useShoppingList &&
                          <IonFabButton onClick={() => setShoppingListModalIsOpen(true)} className='inline-fab-button' id="shopping-list-modal-button">
                            <IonIcon icon={listOutline}></IonIcon>
                          </IonFabButton>
                        } */}

                          {/* Question Button */}
                          {experienceQuestions &&
                            experienceQuestions.length > 0 && (
                              <IonFabButton
                                onClick={() => setQuestionModalIsOpen(true)}
                                className="inline-fab-button"
                                id="question-modal-button"
                              >
                                <IonIcon icon={helpOutline}></IonIcon>
                              </IonFabButton>
                            )}
                        </IonFab>

                        {/* User Registration modal */}
                        <UserRegistrationModal
                          userRegistrationModalIsOpen={
                            userRegistrationModalIsOpen
                          }
                          setUserRegistrationModalIsOpen={
                            setUserRegistrationModalIsOpen
                          }
                        />

                        {/* Menu modal */}
                        <MenuModal
                          menuModalIsOpen={menuModalIsOpen}
                          setMenuModalIsOpen={setMenuModalIsOpen}
                          menuModalTabName={menuModalTabName}
                          openBrowser={openBrowser}
                          userViewsProduct={userViewsProduct}
                        />

                        {/* Shopping Cart modal */}
                        {/* {tenant && brand && event && experience && stores && experienceProducts && authUser && placementId && userLocationState && useShoppingCart &&
                        <ShoppingCartModal
                          tenant={tenant} 
                          brand={brand}
                          event={event} 
                          experience={experience} 
                          stores={stores}
                          cartProductVariants={cartProductVariants} 
                          authUser={authUser} 
                          placementId={placementId} 
                          userLocation={userLocationState}
                          shoppingCartModalIsOpen={shoppingCartModalIsOpen} 
                          setShoppingCartModalIsOpen={setShoppingCartModalIsOpen} />
                      } */}

                        {/* Shopping List modal */}
                        {/* {tenant && brand && event && experience && stores && experienceProducts && authUser && placementId && userLocationState && useShoppingList &&
                        <ShoppingListModal
                          tenant={tenant} 
                          brand={brand}
                          event={event} 
                          experience={experience} 
                          stores={stores}
                          listProductVariants={listProductVariants} 
                          authUser={authUser} 
                          placementId={placementId} 
                          userLocation={userLocationState}
                          shoppingListModalIsOpen={shoppingListModalIsOpen} 
                          setShoppingListModalIsOpen={setShoppingListModalIsOpen}
                          openBrowserModal={openBrowserModal} />
                      } */}

                        {/* Question modal */}
                        {experienceQuestions && (
                          <QuestionModal
                            question={experienceQuestions[0]}
                            questionModalIsOpen={questionModalIsOpen}
                            setQuestionModalIsOpen={setQuestionModalIsOpen}
                          />
                        )}

                        {/* Product Modal */}
                        {productModalExperienceProduct && (
                          <ProductModal
                            experienceProduct={productModalExperienceProduct}
                            productModalIsOpen={productModalIsOpen}
                            setProductModalIsOpen={setProductModalIsOpen}
                            openBrowser={openBrowser}
                            setShoppingCartModalIsOpen={setMenuModalIsOpen}
                          />
                        )}

                        {/* Browser Modal */}
                        {browserUrl && (
                          <BrowserModal
                            browserModalUrl={browserUrl}
                            browserModalIsOpen={browserModalIsOpen}
                            setBrowserModalIsOpen={setBrowserModalIsOpen}
                          />
                        )}

                        {/* Browser Tab */}
                        {browserUrl && (
                          <BrowserTab
                            browserUrl={browserUrl}
                            browserIsOpen={browserIsOpen}
                            setBrowserIsOpen={setBrowserIsOpen}
                          />
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <ExperienceNotLive />
                )}
              </>
            )}
          </IonContent>
        </IonPage>
      </AnalyticsContext.Provider>
    </ExperienceContext.Provider>
  );
};

export default ExperienceComponent;
