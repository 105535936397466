import { db } from "../firebase/firebase";
import { collectionData } from "rxfire/firestore";
import { Observable, of } from "rxjs";
import {
  DocumentData,
  QuerySnapshot,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { ProductVariant } from "../models/product-variant";

function getProductVariantsByIds(
  tenantId?: string,
  productIds?: string[]
): Observable<ProductVariant[]> {
  if (tenantId && productIds && productIds.length > 0) {
    //products are related to experience
    return collectionData(
      query(
        collection(db, "productVariants"),
        where("tenantId", "==", tenantId),
        where("productId", "in", productIds),
        where("deleted", "==", false)
      )
    );
  } else {
    //no products related to experience
    return of([]);
  }
}

async function getAllForProductId(
  tenantId?: string,
  productId?: string
): Promise<ProductVariant[]> {
  try {
    const snapshot: QuerySnapshot<DocumentData> = await getDocs(
      query(
        collection(db, "productVariants"),
        where("tenantId", "==", tenantId),
        where("productId", "==", productId),
        where("deleted", "==", false)
      )
    );
    const productVariants: ProductVariant[] = [];
    if (snapshot.empty) {
      return productVariants;
    } else {
      for (const doc of snapshot.docs) {
        productVariants.push(doc.data());
      }
    }
    return productVariants;
  } catch (err) {
    throw err;
  }
}

const ProductVariantService = {
  getProductVariantsByIds,
  getAllForProductId,
};

export default ProductVariantService;
