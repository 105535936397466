import { db } from '../firebase/firebase';
import { docData } from 'rxfire/firestore';
import { Observable, of } from 'rxjs';
import { doc } from 'firebase/firestore';
import { Brand } from '../models/brand';


function getBrandById(id?: string): Observable<Brand | undefined> {
  if (id) {
    return docData(doc(db, `brands/${id}`))
  } else {
    return of(undefined)
  }
}

const BrandService = {
  getBrandById
};

export default BrandService;