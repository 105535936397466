import { db } from "../firebase/firebase";
import { collection, doc, serverTimestamp, setDoc } from "firebase/firestore";
import { User } from "firebase/auth";
import { UserAccountActions } from "../models/user-account-actions";
import { Experience } from "../models/experience";
import { Store } from "../models/store";
import { ExperienceProduct } from "../models/experience-product";
import { ProductVariant } from "../models/product-variant";

async function logViewExperience(
  experience: Experience,
  placementId: string,
  user: User
): Promise<UserAccountActions> {
  try {
    const userAccountActionId: string = doc(
      collection(db, "userAccountActions")
    ).id;

    const userAccountAction: UserAccountActions = {
      id: userAccountActionId,
      userAccountId: user.uid,
      tenantId: experience.tenantId,
      brandId: experience.brandId,
      experienceId: experience.id,
      placementId: placementId || null,
      isAnonymous: user.isAnonymous,
      userAccountActionType: "view_experience",
      createdDate: serverTimestamp(),
      createdByUserAccountId: user.uid,
      deleted: false,
    };

    // save user account action
    await setDoc(
      doc(db, `userAccountActions/${userAccountActionId}`),
      userAccountAction
    );

    console.log("dbxEvent", "view_experience");

    return userAccountAction;
  } catch (err) {
    throw err;
  }
}

async function logGeneralInteraction(
  eventName:
    | "grant_device_orientation_permission"
    | "deny_device_orientation_permission"
    | "host_video_ended"
    | "host_video_started"
    | "view_welcome_screen"
    | "click_button_to_enter",
  experience: Experience,
  user: User
): Promise<UserAccountActions> {
  try {
    const userAccountActionId: string = doc(
      collection(db, "userAccountActions")
    ).id;

    const userAccountAction: UserAccountActions = {
      id: userAccountActionId,
      userAccountId: user.uid,
      tenantId: experience.tenantId,
      brandId: experience.brandId,
      experienceId: experience.id,
      isAnonymous: user.isAnonymous,
      userAccountActionType: eventName,
      createdDate: serverTimestamp(),
      createdByUserAccountId: user.uid,
      deleted: false,
    };

    // save user account action
    await setDoc(
      doc(db, `userAccountActions/${userAccountActionId}`),
      userAccountAction
    );

    console.log("dbxEvent", eventName);

    return userAccountAction;
  } catch (err) {
    throw err;
  }
}

async function logItemInteraction(
  userAccountActionType: UserAccountActions["userAccountActionType"],
  experience: Experience,
  placementId: string,
  store: Store,
  experienceProduct: ExperienceProduct,
  productVariant: ProductVariant,
  authUser: User
): Promise<UserAccountActions> {
  try {
    console.log("dbxEvent", userAccountActionType);

    const userAccountActionId: string = doc(
      collection(db, "userAccountActions")
    ).id;

    const userAccountAction: UserAccountActions = {
      id: userAccountActionId,
      userAccountId: authUser?.uid,
      tenantId: experience?.tenantId || null,
      brandId: experience?.brandId || null,
      experienceId: experience?.id || null,
      placementId: placementId || null,
      storeId: store?.id || null,
      experienceProductId: experienceProduct?.id || null,
      productId: productVariant?.productId || null,
      productVariantId: productVariant?.id || null,
      userAccountActionType: userAccountActionType,
      isAnonymous: authUser?.isAnonymous,
      createdDate: serverTimestamp(),
      createdByUserAccountId: authUser?.uid,
      deleted: false,
    };

    // save user account action
    await setDoc(
      doc(db, `userAccountActions/${userAccountActionId}`),
      userAccountAction
    );

    return userAccountAction;
  } catch (err) {
    throw err;
  }
}

const UserAccountActionService = {
  logViewExperience,
  logGeneralInteraction,
  logItemInteraction,
};

export default UserAccountActionService;
