import "./PreExperienceLoader.css";
import { quantum } from "ldrs";

interface ContainerProps {}

const PreExperienceLoader: React.FC<ContainerProps> = () => {
  quantum.register("l-quantum");

  return (
    <div className="container">
      <l-quantum size="75" speed="1.75" color="blue"></l-quantum>
      <h3>Getting your space ready</h3>
    </div>
  );
};

export default PreExperienceLoader;
