import { FieldValue, Timestamp } from "firebase/firestore";
import { DateTime } from "luxon";

/**
 * Convert Firestore Timestamp types to Date types
 * @param firestoreDocument
 * @returns firestoreDocumentWithTimestampsConvertedToDates
 */
function convertTimestampsToDate(firestoreDocument: any): any {
  const firestoreDocumentWithTimestampsConvertedToDates: any = {};

  if (firestoreDocument) {
    for (const [key, value] of Object.entries(firestoreDocument)) {
      firestoreDocumentWithTimestampsConvertedToDates[key] =
        value instanceof Timestamp ? value.toDate() : value;
    }
  }

  return firestoreDocumentWithTimestampsConvertedToDates;
}

function convertTimestampsToIsoString(firestoreDocument: any): any {
  const firestoreDocumentWithTimestampsConvertedToDates: any = {};

  if (firestoreDocument) {
    for (const [key, value] of Object.entries(firestoreDocument)) {
      firestoreDocumentWithTimestampsConvertedToDates[key] =
        value instanceof Timestamp ? value.toDate().toISOString() : value;
    }
  }

  return firestoreDocumentWithTimestampsConvertedToDates;
}

function convertDatesToFirestoreTimestamp(firestoreDocument: any): any {
  const firestoreDocumentWithDatesConvertedToIsoStrings: any = {};

  if (firestoreDocument) {
    for (const [key, value] of Object.entries(firestoreDocument)) {
      firestoreDocumentWithDatesConvertedToIsoStrings[key] =
        value instanceof Date ? Timestamp.fromDate(value) : value;
    }
  }

  return firestoreDocumentWithDatesConvertedToIsoStrings;
}

function convertDatesToIsoString(firestoreDocument: any): any {
  const firestoreDocumentWithDatesConvertedToIsoStrings: any = {};

  if (firestoreDocument) {
    for (const [key, value] of Object.entries(firestoreDocument)) {
      firestoreDocumentWithDatesConvertedToIsoStrings[key] =
        value instanceof Date ? value.toISOString() : value;
    }
  }

  return firestoreDocumentWithDatesConvertedToIsoStrings;
}

function convertToLuxonFormat(
  value: string | Date | Timestamp | FieldValue,
  luxonFormat: string
): any {
  if (value instanceof Date)
    return DateTime.fromJSDate(value).toFormat(luxonFormat);

  if (value instanceof Timestamp)
    return DateTime.fromJSDate(value.toDate()).toFormat(luxonFormat);

  if (typeof value === "string")
    return DateTime.fromISO(value).toFormat(luxonFormat);

  return null;
}

const DataTransformationService = {
  convertTimestampsToDate,
  convertTimestampsToIsoString,
  convertDatesToFirestoreTimestamp,
  convertDatesToIsoString,
  convertToLuxonFormat,
};

export default DataTransformationService;
