import { db } from '../firebase/firebase';
import { docData } from 'rxfire/firestore';
import { Observable, of } from 'rxjs';
import { doc } from 'firebase/firestore';
import { Set } from '../models/set';


function getSetById(id?: string): Observable<Set | undefined> {
  if (id) {
    return docData(doc(db, `sets/${id}`))
  } else {
    return of(undefined)
  }
}

const SetService = {
  getSetById
};

export default SetService;