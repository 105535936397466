import { proxy } from 'valtio'


const state = proxy({
    isRoomReady: false,
    isEnterClicked: false,
    isHostReady: false,
    isSoundUnlocked: false,
    isHostFinished: false,
    isCameraReady: false,
    isModalOpen: false,
    activeItem: null,
    isDragging: false
})

export { state }