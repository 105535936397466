import React, { useEffect, useRef, useState } from "react";
import { User } from "firebase/auth";
import {
  collection,
  query,
  where,
  doc,
  setDoc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../firebase/firebase";

import {
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonThumbnail,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import "./ProductModal.css";
import {
  addCircle,
  addOutline,
  cartOutline,
  checkmarkOutline,
  closeCircleOutline,
  removeCircle,
} from "ionicons/icons";
// import { Canvas } from "@react-three/fiber";
// import { useGLTF, Stage, PresentationControls } from "@react-three/drei";
import { Tenant } from "../../../models/tenant";
import { Experience } from "../../../models/experience";
import { state } from "../../../store";
import { CartProductVariant } from "../../../models/cart-product-variant";
import { Store } from "../../../models/store";
import { Event } from "../../../models/event";
import { ProductVariant } from "../../../models/product-variant";
import { ListProductVariant } from "../../../models/list-product-variant";
import { Brand } from "../../../models/brand";
import { ExperienceProduct } from "../../../models/experience-product";
// import { set } from "react-hook-form";
import ProductVariantService from "../../../services/productVariant.service";
// import { firstValueFrom } from "rxjs";
import ProductCard from "../ProductInformation/ProductInformation";
import GoogleAnalyticsService from "../../../services/googleAnalytics.service";
import UserAccountActionService from "../../../services/userAccountAction.service";
import { useAuthUserContext } from "../../../context/AuthUserContext";
import { useExperienceContext } from "../../../context/ExperienceContext";
// import { Selectable } from "../../sets/one-bedroom-house/Shared/Selectable";

interface ContainerProps {
  experienceProduct: ExperienceProduct;
  productModalIsOpen: boolean;
  setProductModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  openBrowser(
    experience: Experience,
    placementId: string,
    store: Store,
    experienceProduct: ExperienceProduct,
    productVariant: ProductVariant,
    url: string,
    urlSupportsIFrameEmbed: boolean,
    authUser: User
  ): void;
  setShoppingCartModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProductModal: React.FC<ContainerProps> = ({
  experienceProduct,
  productModalIsOpen,
  setProductModalIsOpen,
  openBrowser,
  setShoppingCartModalIsOpen,
}) => {
  // Context API
  const { authUser, authUserIsLoading } = useAuthUserContext();
  const {
    tenant,
    brand,
    event,
    experience,
    placementId,
    set,
    experienceProducts,
    experienceQuestions,
    experienceVideos,
    cartProductVariants,
    listProductVariants,
    stores,
    userAccount,
    experienceIsLoading,
  } = useExperienceContext();

  const [store, setStore] = useState<Store>();
  const [shoppingCart, setShoppingCart] = useState<any>();
  const [productVariants, setProductVariants] = useState<ProductVariant[]>();

  useEffect(() => {
    const getData = async () => {
      if (productModalIsOpen) {
        // set store that is related to the product
        const relatedStore = stores.find(
          (storeObj) => storeObj.id === experienceProduct.storeId
        );
        setStore(relatedStore);

        const productVariants: ProductVariant[] =
          await ProductVariantService.getAllForProductId(
            experienceProduct.tenantId,
            experienceProduct.productId
          );

        setProductVariants(productVariants);

        let cartDictionary: any = {};
        for (const cartProductVariant of cartProductVariants) {
          if (
            cartDictionary &&
            cartProductVariant.productVariantId &&
            cartDictionary[String(cartProductVariant.productVariantId)]
          ) {
            cartDictionary[String(cartProductVariant.productVariantId)] =
              cartDictionary[String(cartProductVariant.productVariantId)] + 1;
          } else {
            cartDictionary[String(cartProductVariant.productVariantId)] = 1;
          }
        }

        let newShoppingCart: any = {};
        for (const cartProductVariant of cartProductVariants) {
          newShoppingCart[String(cartProductVariant.productVariantId)] =
            newShoppingCart &&
            newShoppingCart[String(cartProductVariant.productVariantId)]
              ? newShoppingCart[String(cartProductVariant.productVariantId)] + 1
              : 1;
        }

        setShoppingCart(newShoppingCart);

        // analytics events
        if (experience && placementId && authUser) {
          GoogleAnalyticsService.logItemInteraction(
            "view_item",
            experience,
            placementId,
            relatedStore as Store,
            experienceProduct,
            productVariants[0] as ProductVariant
          );
          await UserAccountActionService.logItemInteraction(
            "view_item",
            experience,
            placementId,
            relatedStore as Store,
            experienceProduct,
            productVariants[0] as ProductVariant,
            authUser
          );
        } else {
          console.error(
            "Experience, PlacementId or Auth User not defined to view_item event."
          );
        }
      }

      return;
    };

    getData().catch((err) => {
      alert(err);
      throw err;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productModalIsOpen, experienceProduct, cartProductVariants]);

  const modal = useRef<HTMLIonModalElement>(null);

  // const Model = (props: any) => {
  //   const { scene } = useGLTF(String(experienceProduct.productModelUrl));
  //   return <primitive object={scene} {...props} />;
  // };

  const onDismissModal = () => {
    setProductModalIsOpen(false);
    state.isModalOpen = false;
  };

  /**
   * Add To Cart
   * @param productVariant
   */
  const addOneProductVariantToCart = async (productVariant: ProductVariant) => {
    try {
      if (!authUser?.uid)
        throw new Error("Invalid user account. Please contact support.");
      // Add product to cart
      const cartProductVariantId: string = doc(
        collection(db, "cartProductVariants")
      ).id;
      const cartProductVariant: CartProductVariant = {
        id: cartProductVariantId,
        tenantId: tenant?.id,
        brandId: brand?.id,
        eventId: experience?.eventId,
        experienceId: experience?.id,
        placementId: placementId,
        productVariantId: productVariant.id,
        productId: productVariant.productId,
        userAccountId: authUser?.uid,
        createdDate: serverTimestamp(),
        createdByUserAccountId: authUser?.uid,
        deleted: false,
      };

      await setDoc(
        doc(db, `cartProductVariants/${cartProductVariantId}`),
        cartProductVariant
      );

      // analytics events
      if (store && experience && placementId) {
        GoogleAnalyticsService.logItemInteraction(
          "add_to_cart",
          experience,
          placementId,
          store,
          experienceProduct,
          productVariant
        );
        await UserAccountActionService.logItemInteraction(
          "add_to_cart",
          experience,
          placementId,
          store,
          experienceProduct,
          productVariant,
          authUser
        );
      }
    } catch (err) {
      alert(err);
    }
  };

  /**
   * Remove from Cart
   * @param productVariant
   */
  const subtractOneProductVariantFromCart = async (
    productVariant: ProductVariant
  ) => {
    try {
      for (const cartProductVariant of cartProductVariants) {
        if (cartProductVariant.productVariantId === productVariant.id) {
          if (!authUser?.uid)
            throw new Error("Invalid user account. Please contact support.");
          // Remove product from cart
          await updateDoc(
            doc(db, `cartProductVariants/${cartProductVariant.id}`),
            {
              updatedDate: serverTimestamp(),
              updatedByUserAccountId: authUser?.uid,
              deleted: true,
            }
          );

          // analytics events
          if (store && experience && placementId) {
            GoogleAnalyticsService.logItemInteraction(
              "remove_from_cart",
              experience,
              placementId,
              store,
              experienceProduct,
              productVariant
            );
            await UserAccountActionService.logItemInteraction(
              "remove_from_cart",
              experience,
              placementId,
              store,
              experienceProduct,
              productVariant,
              authUser
            );
          }

          break;
        }
      }
    } catch (err) {
      alert(err);
      throw err;
    }
  };

  /**
   * Add to List
   * @param productVariant
   */
  const addOneProductVariantToList = async (productVariant: ProductVariant) => {
    try {
      if (!authUser?.uid)
        throw new Error("Invalid user account. Please contact support.");
      // Add product to list
      const listProductVariantId: string = doc(
        collection(db, "listProductVariants")
      ).id;
      const listProductVariant: ListProductVariant = {
        id: listProductVariantId,
        tenantId: tenant?.id,
        brandId: brand?.id,
        eventId: experience?.eventId,
        experienceId: experience?.id,
        placementId: placementId,
        productVariantId: productVariant.id,
        productId: productVariant.productId,
        userAccountId: authUser?.uid,
        createdDate: serverTimestamp(),
        createdByUserAccountId: authUser?.uid,
        deleted: false,
      };

      await setDoc(
        doc(db, `listProductVariants/${listProductVariantId}`),
        listProductVariant
      );

      // analytics events
      if (store && experience && placementId) {
        GoogleAnalyticsService.logItemInteraction(
          "add_to_wishlist",
          experience,
          placementId,
          store,
          experienceProduct,
          productVariant
        );
        await UserAccountActionService.logItemInteraction(
          "add_to_wishlist",
          experience,
          placementId,
          store,
          experienceProduct,
          productVariant,
          authUser
        );
      }
    } catch (err) {
      alert(err);
    }
  };

  /**
   * Remove from List
   * @param productVariant
   */
  const subtractOneProductVariantFromList = async (
    productVariant: ProductVariant
  ) => {
    try {
      for (const listProductVariant of listProductVariants) {
        if (listProductVariant.productVariantId === productVariant.id) {
          if (!authUser?.uid)
            throw new Error("Invalid user account. Please contact support.");
          // Remove product from list
          await updateDoc(
            doc(db, `listProductVariants/${listProductVariant.id}`),
            {
              updatedDate: serverTimestamp(),
              updatedByUserAccountId: authUser?.uid,
              deleted: true,
            }
          );

          // analytics events
          if (store && experience && placementId) {
            GoogleAnalyticsService.logItemInteraction(
              "remove_from_wishlist",
              experience,
              placementId,
              store,
              experienceProduct,
              productVariant
            );
            await UserAccountActionService.logItemInteraction(
              "remove_from_wishlist",
              experience,
              placementId,
              store,
              experienceProduct,
              productVariant,
              authUser
            );
          }
          break;
        }
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <IonModal
      handle={true}
      ref={modal}
      isOpen={productModalIsOpen}
      onDidDismiss={onDismissModal}
      initialBreakpoint={0.5}
      breakpoints={[0, 0.5, 1]}
      backdropDismiss={false}
      backdropBreakpoint={0.5} // disable backdrop and allow for interaction with the 3D environment while modal is displayed
      animated={false}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle
            className="ion-text-wrap"
            style={{ paddingLeft: "30px", paddingRight: "30px" }}
          >
            {experienceProduct?.productName}
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onDismissModal} color="medium" size="large">
              <IonIcon icon={closeCircleOutline}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {experienceProduct?.productDescription &&
          experienceProduct?.productDescription.length > 0 && (
            <div className="ion-margin">
              <p>{experienceProduct?.productDescription}</p>
            </div>
          )}

        <ProductCard
          experienceProduct={experienceProduct}
          openBrowser={openBrowser}
          allowProductRemoval={false}
        ></ProductCard>
      </IonContent>
    </IonModal>
  );
};

export default ProductModal;
