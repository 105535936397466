import { db } from '../firebase/firebase';
import { collectionData } from 'rxfire/firestore';
import { Observable, of } from 'rxjs';
import { collection, query, where } from 'firebase/firestore';
import { Experience } from '../models/experience';
import { ExperienceVideo } from '../models/experience-video';


function getAllByExperience(experience?: Experience): Observable<ExperienceVideo[]> {
  if (experience) { //videos are related to experience
    return collectionData(
      query(
        collection(db, 'experienceVideos'),
        where("tenantId", "==", experience.tenantId),
        where("brandId", "==", experience.brandId),
        where("experienceId", "==", experience.id),
        where("deleted", "==", false)
      )
    );
  } else { //no experienceVideos related to experience
    return of([])
  }
}

const ExperienceVideoService = {
  getAllByExperience
};

export default ExperienceVideoService;