import React, { useRef, useEffect, useState, useContext } from "react";
import { User } from "firebase/auth";

import {
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import "./MenuModal.css";
import { Tenant } from "../../../models/tenant";
import { Experience } from "../../../models/experience";
import { Store } from "../../../models/store";
import { Event } from "../../../models/event";
import { ListProductVariant } from "../../../models/list-product-variant";
import { Brand } from "../../../models/brand";
import { closeCircleOutline, expandOutline } from "ionicons/icons";
import { CartProductVariant } from "../../../models/cart-product-variant";
import { ExperienceProduct } from "../../../models/experience-product";
import ProductCard from "../ProductInformation/ProductInformation";
import { ProductVariant } from "../../../models/product-variant";
import { useExperienceContext } from "../../../context/ExperienceContext";
import { useAuthUserContext } from "../../../context/AuthUserContext";
interface ContainerProps {
  menuModalIsOpen: boolean;
  setMenuModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  menuModalTabName: "saved" | "products" | undefined;
  openBrowser(
    experience: Experience,
    placementId: string,
    store: Store,
    experienceProduct: ExperienceProduct,
    productVariant: ProductVariant,
    url: string,
    urlSupportsIFrameEmbed: boolean,
    authUser: User
  ): void;
  userViewsProduct(experienceProduct: ExperienceProduct): Promise<void>;
}

const MenuModal: React.FC<ContainerProps> = ({
  menuModalIsOpen,
  setMenuModalIsOpen,
  menuModalTabName,
  openBrowser,
  userViewsProduct,
}) => {
  // Context API
  const { authUser, authUserIsLoading } = useAuthUserContext();
  const {
    tenant,
    brand,
    event,
    experience,
    placementId,
    set,
    experienceProducts,
    experienceQuestions,
    experienceVideos,
    cartProductVariants,
    listProductVariants,
    stores,
    userAccount,
    experienceIsLoading,
  } = useExperienceContext();

  const modal = useRef<HTMLIonModalElement>(null);
  const [atMaxBreakpoint, setAtMaxBreakpoint] = useState<boolean>(false);
  const [savedListOfExperienceProducts, setSavedListOfExperienceProducts] =
    useState<ExperienceProduct[]>([]);
  const [page, setPage] = useState<string>();

  const onDismissModal = () => {
    setAtMaxBreakpoint(false);
    setMenuModalIsOpen(false);
  };

  useEffect(() => {
    const newListOfExperienceProducts: ExperienceProduct[] = [];
    for (const listProductVariant of listProductVariants) {
      const matchingExperienceProduct = experienceProducts.find(
        (experienceProduct) =>
          experienceProduct.productId === listProductVariant.productId
      );
      if (matchingExperienceProduct) {
        newListOfExperienceProducts.push(matchingExperienceProduct);
      }
    }
    setSavedListOfExperienceProducts(newListOfExperienceProducts);
  }, [listProductVariants]);

  useEffect(() => {
    console.log("menuModalTabName", menuModalTabName);
    if (menuModalTabName) {
      setPage(menuModalTabName);
    } else {
      if (!page) setPage("saved");
    }
  }, [menuModalTabName, menuModalIsOpen]);

  /**
   * Expands the modal to the maximum breakpoint.
   * @returns {Promise<void>} A promise that resolves when the modal is expanded.
   * @throws {Error} If no breakpoints are found on the modal.
   */
  async function expandModalToMaxBreakpoint(): Promise<void> {
    try {
      // get array of breakpoints from modal
      const breakpoints = modal.current?.breakpoints;
      if (!breakpoints || breakpoints.length === 0)
        throw Error("No breakpoints found on modal");

      // get max breakpoint from array
      const maxBreakpoint = breakpoints[breakpoints.length - 1];
      await modal.current?.setCurrentBreakpoint(maxBreakpoint);
      return;
    } catch (error) {
      throw error;
    }
  }

  /**
   * Checks if the current breakpoint is at the maximum breakpoint.
   * @returns A Promise that resolves to void.
   * @throws Throws an error if an error occurs during the process.
   */
  async function isBreakpointAtMax(): Promise<void> {
    try {
      // get array of breakpoints from modal
      const breakpoints = modal.current?.breakpoints;
      if (!breakpoints || breakpoints.length === 0) {
        setAtMaxBreakpoint(true);
        return;
      }

      // get max breakpoint from array
      const maxBreakpoint = breakpoints[breakpoints.length - 1];
      const currentBreakpoint = await modal.current?.getCurrentBreakpoint();

      if (currentBreakpoint === maxBreakpoint) {
        setAtMaxBreakpoint(true);
      } else {
        setAtMaxBreakpoint(false);
      }
      return;
    } catch (error) {
      throw error;
    }
  }

  async function share(): Promise<void> {
    try {
      if (navigator.canShare({ url: window.location.href })) {
        await navigator.share({
          url: window.location.href,
          title: "Space Invaders - Shop the Home!",
          text: "Check out this 3D shopping experience!",
        });
      }
    } catch (error) {
      console.log(error); //avoiding error when user cancels share
    }
  }

  return (
    <IonModal
      handle={true}
      ref={modal}
      isOpen={menuModalIsOpen}
      showBackdrop={false}
      initialBreakpoint={0.5}
      breakpoints={[0, 0.5, 1]}
      onDidDismiss={onDismissModal}
      onIonBreakpointDidChange={() => isBreakpointAtMax()}
      backdropDismiss={false}
      backdropBreakpoint={0.5} // disable backdrop and allow for interaction with the 3D environment while modal is displayed
      animated={false}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start" className="with-badge">
            {!atMaxBreakpoint && (
              <IonButton
                onClick={() => expandModalToMaxBreakpoint()}
                color="medium"
              >
                <IonIcon icon={expandOutline}></IonIcon>
              </IonButton>
            )}
          </IonButtons>
          <IonTitle>Menu</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onDismissModal} color="medium" size="large">
              <IonIcon icon={closeCircleOutline}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>

        {/* Menu Buttons */}
        <IonToolbar>
          <div className="horizontal-scroll ion-text-center">
            <IonButton
              color="dark"
              size="small"
              fill={page === "saved" ? "solid" : "outline"}
              onClick={() => setPage("saved")}
            >
              Saved{" "}
              {savedListOfExperienceProducts.length > 0 && (
                <IonBadge color="success" style={{ marginLeft: "4px" }}>
                  {savedListOfExperienceProducts.length}
                </IonBadge>
              )}
            </IonButton>
            <IonButton
              color="dark"
              size="small"
              fill={page === "products" ? "solid" : "outline"}
              onClick={() => setPage("products")}
            >
              All Products
            </IonButton>
            {/* <IonButton
              color="dark"
              size="small"
              fill={page === "info" ? "solid" : "outline"}
              onClick={() => setPage("info")}
            >
              Info
            </IonButton> */}
            <IonButton
              color="dark"
              size="small"
              fill={page === "share" ? "solid" : "outline"}
              onClick={() => share()}
            >
              Share
            </IonButton>
          </div>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {page === "saved" && (
          <div className="ion-padding">
            {savedListOfExperienceProducts?.length === 0 && (
              <div className="ion-text-center">
                <IonLabel>No products have been saved.</IonLabel>
                <br />
                <IonButton size="small" onClick={() => setPage("products")}>
                  View all products
                </IonButton>
              </div>
            )}
            {savedListOfExperienceProducts.map((experienceProduct, index) => (
              <IonCard key={index} className="product-card">
                <IonCardHeader
                  onClick={() => userViewsProduct(experienceProduct)}
                >
                  <h4>{experienceProduct.productName}</h4>
                </IonCardHeader>
                <IonCardContent>
                  <ProductCard
                    experienceProduct={experienceProduct}
                    openBrowser={openBrowser}
                    allowProductRemoval={true}
                  ></ProductCard>
                </IonCardContent>
              </IonCard>
            ))}
          </div>
        )}

        {page === "products" && (
          <div className="ion-padding">
            {experienceProducts.map((experienceProduct, index) => (
              <IonCard key={index} className="product-card">
                <IonCardHeader
                  onClick={() => userViewsProduct(experienceProduct)}
                >
                  <h4>{experienceProduct.productName}</h4>
                </IonCardHeader>
                <IonCardContent>
                  <ProductCard
                    experienceProduct={experienceProduct}
                    openBrowser={openBrowser}
                    allowProductRemoval={false}
                  ></ProductCard>
                </IonCardContent>
              </IonCard>
            ))}
          </div>
        )}

        {page === "info" && (
          <div className="ion-padding">
            <IonLabel>Info - Coming Soon!</IonLabel>
          </div>
        )}

        {page === "share" && (
          <div className="ion-padding">
            <IonLabel>Share - Coming Soon!</IonLabel>
          </div>
        )}
      </IonContent>
    </IonModal>
  );
};

export default MenuModal;
