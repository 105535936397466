import { db } from "../firebase/firebase";
import { collectionData } from "rxfire/firestore";
import { Observable, map, of } from "rxjs";
import {
  DocumentData,
  Query,
  QueryConstraint,
  collection,
  query,
  where,
} from "firebase/firestore";
import { Experience } from "../models/experience";
import { ExperienceProduct } from "../models/experience-product";
import DataTransformationService from "./data-transformation.service";

function getAllByExperience(
  experience?: Experience
): Observable<ExperienceProduct[]> {
  if (experience) {
    //products are related to experience
    const collectionRef = collection(db, "experienceProducts");

    const queryConstraint: QueryConstraint[] = [
      where("tenantId", "==", experience.tenantId),
      where("brandId", "==", experience.brandId),
      where("experienceId", "==", experience.id),
      where("deleted", "==", false),
    ];

    const collectionQuery: Query<DocumentData> = query(
      collectionRef,
      ...queryConstraint
    );

    return collectionData(collectionQuery).pipe(
      map((collection: DocumentData[]) => {
        //sort by order
        collection.sort(
          (a, b) =>
            (a.experienceProductOrder ? a.experienceProductOrder : 0) -
            (b.experienceProductOrder ? b.experienceProductOrder : 0)
        );
        //convert timestamps to Date
        collection = collection.map((firestoreDocument) =>
          DataTransformationService.convertTimestampsToDate(firestoreDocument)
        );
        return collection;
      })
    );
  } else {
    //no experienceProducts related to experience
    return of([]);
  }
}

const ExperienceProductService = {
  getAllByExperience,
};

export default ExperienceProductService;
