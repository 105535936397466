import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import ExperienceComponent from "./pages/Experience/Experience";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/App.css";
import { AuthUserProvider } from "./context/AuthUserContext";
import NotFound from "./pages/NotFound/NotFound";

setupIonicReact({
  mode: "ios", //default to iOS style
  platform: {
    /** The default `desktop` function returns false for devices with a touchscreen.
     * This is not always wanted, so this function tests the User Agent instead.
     **/
    // desktop: (win) => {
    //   const isMobile =
    //     /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    //       win.navigator.userAgent
    //     );
    //   return !isMobile;
    // },
  },
});

const App: React.FC = () => (
  <AuthUserProvider>
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          {/* Show the user the expected experience based on experience ID and placementId */}
          <Route exact path="/experience/:experienceId/placement/:placementId">
            <ExperienceComponent />
          </Route>
          {/* Override any date restrictions by using a url parameter at the end of the path 'bypass' */}
          <Route exact path="/experience/:experienceId/test/:testAccessId">
            <ExperienceComponent />
          </Route>
          {/* Show a "not found" page, used for situations where path does not match */}
          <Route exact path="/not-found">
            <NotFound />
          </Route>
          {/* Redirect all unmatched routes the "NotFound" page */}
          <Route>
            <NotFound />
          </Route>
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  </AuthUserProvider>
);

export default App;
