import { IonCard, IonCardContent, IonCol, IonGrid, IonRow } from "@ionic/react";
import { Experience } from "../../../models/experience";
import "./QRCodePage.css";
import { QRCode } from "react-qrcode-logo";
import { useAuthUserContext } from "../../../context/AuthUserContext";
import { useExperienceContext } from "../../../context/ExperienceContext";

const QRCodePage: React.FC = () => {
  // Context API
  const { authUser, authUserIsLoading } = useAuthUserContext();
  const {
    tenant,
    brand,
    event,
    experience,
    placementId,
    set,
    experienceProducts,
    experienceQuestions,
    experienceVideos,
    cartProductVariants,
    listProductVariants,
    stores,
    userAccount,
    experienceIsLoading,
  } = useExperienceContext();

  return (
    <div
      className="qr-code-page-background"
      style={{
        backgroundImage:
          'url("/assets/textures/one-bedroom-house/bgblurreddesktop.webp")',
      }}
    >
      <div className="qr-code-page-container">
        <IonCard className="qr-code-page-card">
          <IonCardContent>
            <IonGrid>
              <IonRow>
                <IonCol size="12" className="qr-code-page-col">
                  <img
                    src="/assets/images/space-invaders-hero.jpg"
                    className="qr-code-page-hero-image"
                    alt="Space Invaders"
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="12" className="qr-code-page-col">
                  <strong>
                    This Virtual Store is a 3D experience on your mobile phone.
                    Scan this QR code to get started.
                  </strong>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="6" className="qr-code-page-col">
                  <img
                    src="/assets/images/space-invaders-phone-example.jpg"
                    className="qr-code-page-phone-image"
                    alt=""
                  />
                </IonCol>
                <IonCol size="6" className="qr-code-page-col">
                  <QRCode
                    size={256}
                    qrStyle="dots"
                    value={window.location.href}
                    id={"qrcode"}
                    style={{ width: "100%" }}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol className="qr-code-page-col">
                  <p>Experience powered by DRAWBRIDGE LABS</p>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardContent>
        </IonCard>
      </div>
    </div>
  );
};

export default QRCodePage;
