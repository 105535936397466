import { db } from '../firebase/firebase';
import { docData } from 'rxfire/firestore';
import { Observable, of } from 'rxjs';
import { doc } from 'firebase/firestore';
import { Tenant } from '../models/tenant';


function getTenantById(id?: string): Observable<Tenant> {
  return docData(doc(db, `tenants/${id}`))
}

const TenantService = {
  getTenantById
};

export default TenantService;