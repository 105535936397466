import { db } from '../firebase/firebase';
import { collectionData } from 'rxfire/firestore';
import { Observable, of } from 'rxjs';
import { collection, query, where } from 'firebase/firestore';
import { ListProductVariant } from '../models/list-product-variant';


function getListProductVariantsByUserAndEvent(tenantId?: string, userAccountId?: string, eventId?: string): Observable<ListProductVariant[]> {
  if (tenantId && userAccountId && eventId) {
    return collectionData(
      query(collection(db, `listProductVariants`),
      where('tenantId', '==', tenantId), 
      where('userAccountId', '==', userAccountId),
      where('eventId', '==', eventId),
      where('deleted', '==', false)))
  } else {
    return of([])
  }
}

const ListProductVariantService = {
  getListProductVariantsByUserAndEvent
};

export default ListProductVariantService;