import { db, functionsUrl } from "../firebase/firebase";
import { docData } from "rxfire/firestore";
import { Observable, of } from "rxjs";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { User } from "firebase/auth";
import { UserAccount } from "../models/user-account";
import axios from "axios";
import { UserLocation } from "../models/user-location";

function getUserAccountById(
  userId?: string
): Observable<UserAccount | undefined> {
  if (userId) {
    return docData(doc(db, `userAccounts/${userId}`));
  } else {
    return of(undefined);
  }
}

async function createUserAccount(tenantId: string, authUser: User) {
  // prepare to save user account
  const userAccountToSave: UserAccount = {
    id: authUser.uid,
    lastExperienceAccess: serverTimestamp(),
    tenantId: tenantId,
    updatedByUserAccountId: authUser.uid,
    updatedDate: serverTimestamp(),
  };

  // save user account
  await setDoc(doc(db, `userAccounts/${authUser.uid}`), userAccountToSave, {
    merge: true,
  });
}

async function setUserAccountLocation(
  tenantId: string,
  authUser: User
): Promise<void> {
  try {
    //get Location Data
    const token = await authUser.getIdToken();
    const client = axios.create({
      baseURL: `${functionsUrl}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const locationResponse = await client
      .get("/user-accounts/location")
      .catch((err) => console.error(err));
    const updatedUserLocation: UserLocation =
      locationResponse?.status === 200 ? locationResponse?.data : null;

    // prepare to save user account
    const userAccountToSave: UserAccount = {
      id: authUser.uid,
      lastExperienceAccess: serverTimestamp(),
      tenantId: tenantId,
      updatedByUserAccountId: authUser.uid,
      updatedDate: serverTimestamp(),
    };

    // only update location parameters if they were returned
    if (updatedUserLocation?.country)
      userAccountToSave.country = updatedUserLocation.country;
    if (updatedUserLocation?.region)
      userAccountToSave.region = updatedUserLocation.region;
    if (updatedUserLocation?.city)
      userAccountToSave.city = updatedUserLocation.city;
    if (updatedUserLocation?.cityLatLong)
      userAccountToSave.cityLatLong = updatedUserLocation.cityLatLong;
    if (updatedUserLocation?.ipAddress)
      userAccountToSave.ipAddress = updatedUserLocation.ipAddress;

    // save user account
    await setDoc(doc(db, `userAccounts/${authUser.uid}`), userAccountToSave, {
      merge: true,
    });

    return;
  } catch (err) {
    console.error(err);
  }
}

const UserAccountService = {
  getUserAccountById,
  createUserAccount,
  setUserAccountLocation,
};

export default UserAccountService;
