import React, { FormEventHandler, useEffect, useRef, useState } from "react";
import { User } from "firebase/auth";
import {
  collection,
  query,
  where,
  doc,
  setDoc,
  serverTimestamp,
  updateDoc,
  Timestamp,
  FieldValue,
} from "firebase/firestore";
import { db } from "../../../firebase/firebase";

import {
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonThumbnail,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";

import "./UserRegistrationModal.css";
import { Event } from "../../../models/event";
import { UserAccount } from "../../../models/user-account";
import { useAuthUserContext } from "../../../context/AuthUserContext";
import { useExperienceContext } from "../../../context/ExperienceContext";

interface ContainerProps {
  userRegistrationModalIsOpen: boolean;
  setUserRegistrationModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserRegistrationModal: React.FC<ContainerProps> = ({
  userRegistrationModalIsOpen,
  setUserRegistrationModalIsOpen,
}) => {
  // Context API
  const { authUser, authUserIsLoading } = useAuthUserContext();
  const {
    tenant,
    brand,
    event,
    experience,
    placementId,
    set,
    experienceProducts,
    experienceQuestions,
    experienceVideos,
    cartProductVariants,
    listProductVariants,
    stores,
    userAccount,
    experienceIsLoading,
  } = useExperienceContext();

  const [loading, setLoading] = useState<boolean>(false);

  // Form Field Values
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [postcode, setPostcode] = useState("");
  const [showConfirmationToast, setShowConfirmationToast] = useState(false);

  const [canDismiss, setCanDismiss] = useState(false);

  useEffect(() => {
    const getData = async () => {
      if (userAccount?.firstName) setFirstName(userAccount.firstName);

      if (userAccount && event && event.requireRegistrationBeforeExperience) {
        //check if user registration fields are required, and show registration modal if necessary
        if (
          checkIfUserHasUnansweredUserRegistrationFields(event, userAccount)
        ) {
          setUserRegistrationModalIsOpen(true);
        }
      } else {
        setUserRegistrationModalIsOpen(false);
      }

      return;
    };

    getData().catch((err) => {
      alert(err);
      throw err;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccount]);

  function checkIfUserHasUnansweredUserRegistrationFields(
    event: Event,
    userAccount: UserAccount
  ): boolean {
    if (
      checkIfStringFieldNeedsFilledIn(
        event.userRegistrationFieldRequirements_firstName,
        userAccount.firstName
      )
    )
      return true;
    if (
      checkIfStringFieldNeedsFilledIn(
        event.userRegistrationFieldRequirements_lastName,
        userAccount.lastName
      )
    )
      return true;
    if (
      checkIfStringFieldNeedsFilledIn(
        event.userRegistrationFieldRequirements_emailAddress,
        userAccount.emailAddress
      )
    )
      return true;
    if (
      checkIfStringFieldNeedsFilledIn(
        event.userRegistrationFieldRequirements_phoneNumber,
        userAccount.phoneNumber
      )
    )
      return true;
    if (
      checkIfStringFieldNeedsFilledIn(
        event.userRegistrationFieldRequirements_postcode,
        userAccount.postcode
      )
    )
      return true;
    if (
      checkIfStringFieldNeedsFilledIn(
        event.userRegistrationFieldRequirements_gender,
        userAccount.gender
      )
    )
      return true;
    if (
      checkIfTimestampFieldNeedsFilledIn(
        event.userRegistrationFieldRequirements_dateOfBirth,
        userAccount.dateOfBirth
      )
    )
      return true;
    if (
      checkIfBooleanFieldNeedsFilledIn(
        event.userRegistrationFieldRequirements_optInMarketing,
        userAccount.optInMarketing
      )
    )
      return true;

    return false;
  }

  // Check if the STRING field value entered meets the requirement
  function checkIfStringFieldNeedsFilledIn(
    requirement: "required" | "optional" | "notUsed" | undefined,
    userAccountFieldValue: string | null | undefined
  ): boolean {
    if (
      (requirement === ("required" || "optional") &&
        userAccountFieldValue === undefined) ||
      (requirement === "required" && userAccountFieldValue?.length === 0)
    ) {
      return true;
    } else {
      return false;
    }
  }

  // Check if the BOOLEAN field value entered meets the requirement
  function checkIfBooleanFieldNeedsFilledIn(
    requirement: "required" | "optional" | "notUsed" | undefined,
    userAccountFieldValue: boolean | null | undefined
  ): boolean {
    if (
      (requirement === ("required" || "optional") &&
        (userAccountFieldValue === undefined ||
          userAccountFieldValue === null)) ||
      (requirement === "required" && userAccountFieldValue === false)
    ) {
      return true;
    } else {
      return false;
    }
  }

  // Check if the TIMESTAMP field value entered meets the requirement
  function checkIfTimestampFieldNeedsFilledIn(
    requirement: "required" | "optional" | "notUsed" | undefined,
    userAccountFieldValue: string | Timestamp | FieldValue | null | undefined
  ): boolean {
    if (
      requirement === ("required" || "optional") &&
      (userAccountFieldValue === undefined || userAccountFieldValue === null)
    ) {
      return true;
    } else {
      return false;
    }
  }

  const modal = useRef<HTMLIonModalElement>(null);

  const onDismissModal = () => {
    setUserRegistrationModalIsOpen(false);
  };

  async function handleSubmit(e: any) {
    e.preventDefault();

    try {
      setLoading(true);
      let updatedUserAccount: UserAccount = {
        id: userAccount?.id,
      };
      if (
        event?.userRegistrationFieldRequirements_firstName === "required" &&
        !firstName
      )
        throw new Error("Please provide your first name.");
      if (
        event?.userRegistrationFieldRequirements_firstName === "optional" ||
        event?.userRegistrationFieldRequirements_firstName === "required"
      )
        updatedUserAccount.firstName = firstName;

      if (
        event?.userRegistrationFieldRequirements_lastName === "required" &&
        !lastName
      )
        throw new Error("Please provide your last name.");
      if (
        event?.userRegistrationFieldRequirements_lastName === "optional" ||
        event?.userRegistrationFieldRequirements_lastName === "required"
      )
        updatedUserAccount.lastName = lastName;

      if (
        event?.userRegistrationFieldRequirements_emailAddress === "required" &&
        !emailAddress
      )
        throw new Error("Please provide your email address.");
      if (
        event?.userRegistrationFieldRequirements_emailAddress === "optional" ||
        event?.userRegistrationFieldRequirements_emailAddress === "required"
      )
        updatedUserAccount.emailAddress = emailAddress;

      if (
        event?.userRegistrationFieldRequirements_phoneNumber === "required" &&
        !phoneNumber
      )
        throw new Error("Please provide your phone number.");
      if (
        event?.userRegistrationFieldRequirements_phoneNumber === "optional" ||
        event?.userRegistrationFieldRequirements_phoneNumber === "required"
      )
        updatedUserAccount.phoneNumber = phoneNumber;

      if (
        event?.userRegistrationFieldRequirements_postcode === "required" &&
        !postcode
      )
        throw new Error("Please provide your postcode.");
      if (
        event?.userRegistrationFieldRequirements_postcode === "optional" ||
        event?.userRegistrationFieldRequirements_postcode === "required"
      )
        updatedUserAccount.phoneNumber = postcode;

      await setDoc(
        doc(db, `userAccounts/${updatedUserAccount.id}`),
        updatedUserAccount,
        { merge: true }
      );
      setCanDismiss(true);
      setLoading(false);
      setShowConfirmationToast(true);
      setUserRegistrationModalIsOpen(false);
    } catch (err) {
      setLoading(false);
      alert(err);
      throw err;
    }
  }

  return (
    <div>
      <IonModal
        canDismiss={canDismiss}
        handle={false}
        className="fullwidth"
        ref={modal}
        isOpen={userRegistrationModalIsOpen}
        onDidDismiss={onDismissModal}
        initialBreakpoint={1}
        breakpoints={[0, 1]}
        showBackdrop={false}
        animated={false}
      >
        <IonContent>
          <form onSubmit={handleSubmit}>
            <IonList>
              {event &&
                (event.userRegistrationFieldRequirements_firstName ===
                  "optional" ||
                  event.userRegistrationFieldRequirements_firstName ===
                    "required") && (
                  <IonItem>
                    <IonLabel position="stacked">First Name</IonLabel>
                    <IonInput
                      name="firstName"
                      placeholder="Enter your first name"
                      type="text"
                      value={firstName}
                      onIonChange={(e) => setFirstName(String(e.detail.value))}
                    ></IonInput>
                  </IonItem>
                )}

              {event &&
                (event.userRegistrationFieldRequirements_lastName ===
                  "optional" ||
                  event.userRegistrationFieldRequirements_lastName ===
                    "required") && (
                  <IonItem>
                    <IonLabel position="stacked">Last Name</IonLabel>
                    <IonInput
                      name="lastName"
                      placeholder="Enter your last name"
                      type="text"
                      value={lastName}
                      onIonChange={(e) => setLastName(String(e.detail.value))}
                    ></IonInput>
                  </IonItem>
                )}

              {event &&
                (event.userRegistrationFieldRequirements_emailAddress ===
                  "optional" ||
                  event.userRegistrationFieldRequirements_emailAddress ===
                    "required") && (
                  <IonItem>
                    <IonLabel position="stacked">Email Address</IonLabel>
                    <IonInput
                      name="emailAddress"
                      placeholder="Enter your email address"
                      type="email"
                      value={emailAddress}
                      onIonChange={(e) =>
                        setEmailAddress(String(e.detail.value))
                      }
                    ></IonInput>
                  </IonItem>
                )}

              {event &&
                (event.userRegistrationFieldRequirements_phoneNumber ===
                  "optional" ||
                  event.userRegistrationFieldRequirements_phoneNumber ===
                    "required") && (
                  <IonItem>
                    <IonLabel position="stacked">Phone Number</IonLabel>
                    <IonInput
                      name="phoneNumber"
                      placeholder="Enter your phone number"
                      type="tel"
                      value={phoneNumber}
                      onIonChange={(e) =>
                        setPhoneNumber(String(e.detail.value))
                      }
                    ></IonInput>
                  </IonItem>
                )}

              {event &&
                (event.userRegistrationFieldRequirements_postcode ===
                  "optional" ||
                  event.userRegistrationFieldRequirements_postcode ===
                    "required") && (
                  <IonItem>
                    <IonLabel position="stacked">Postcode</IonLabel>
                    <IonInput
                      name="postcode"
                      placeholder="Enter your postcode"
                      type="text"
                      value={postcode}
                      onIonChange={(e) => setPostcode(String(e.detail.value))}
                    ></IonInput>
                  </IonItem>
                )}
            </IonList>
            <IonButton
              type="submit"
              color={"success"}
              fill={"solid"}
              expand={"block"}
              className="ion-margin"
              id="submit-user-registration"
            >
              Register
            </IonButton>
          </form>

          {/*/!* Loading spinner until form is submitted *!/*/}
          <IonLoading isOpen={loading}></IonLoading>
        </IonContent>
      </IonModal>

      <IonToast
        position="top"
        isOpen={showConfirmationToast}
        onDidDismiss={() => setShowConfirmationToast(false)}
        message="Thank you for registering."
        duration={3000}
      ></IonToast>
    </div>
  );
};

export default UserRegistrationModal;
